<template>
  <footer class="main-footer">
    <p>© {{ new Date().getFullYear() }} Автопартнер</p>
    <p>
      Разработка
      <!-- <a class="link-dev" href="#" target="_blank">Rattlesnake</a> -->
      <span class="link-dev">Rattlesnake</span>
    </p>
  </footer>
</template>

<style scoped lang="scss">
.main-footer {
  display: flex;
  justify-content: space-between;
  padding: 14px 0;
  margin-top: 20px;
  border-top: 1px solid #e5e5e5;
  p {
    color: #c5c5c5;
    font-family: Inter;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 0;
  }
  a {
    &:hover {
      // text-decoration: underline;
    }
  }
}
</style>
