<template>
  <!-- Header -->
  <div class="header">
    <!-- Logo -->
    <div class="header-left active">
      <router-link to="/requests" class="logo logo-normal">
        <img src="@/assets/img/logo.svg" alt="Logo" />
        <img src="@/assets/img/white-logo.svg" class="white-logo" alt="Logo" />
      </router-link>
      <router-link to="/requests" class="logo-small">
        <img src="@/assets/img/logo-small.svg" alt="Logo" />
      </router-link>
    </div>
    <!-- /Logo -->

    <!-- Mobile Notification -->
    <div class="mobile-notification">
      <router-link to="/activities">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
        >
          <g clip-path="url(#clip0_2042_429)">
            <path
              d="M10.0001 0.714294C11.6255 0.714294 13.1843 1.35998 14.3336 2.50931C15.483 3.65864 16.1286 5.21747 16.1286 6.84287C16.1286 13.6572 18.6144 15 19.2858 15H0.714355C1.40007 15 3.8715 13.6429 3.8715 6.84287C3.8715 5.21747 4.51719 3.65864 5.66652 2.50931C6.81585 1.35998 8.37467 0.714294 10.0001 0.714294Z"
              fill="#005534"
              stroke="#005534"
              stroke-width="0.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M7.85742 17.6143C7.98091 18.0886 8.25828 18.5086 8.64607 18.8084C9.03386 19.1081 9.51014 19.2707 10.0003 19.2707C10.4904 19.2707 10.9667 19.1081 11.3545 18.8084C11.7423 18.5086 12.0196 18.0886 12.1431 17.6143"
              fill="#005534"
            />
            <path
              d="M7.85742 17.6143C7.98091 18.0886 8.25828 18.5086 8.64607 18.8084C9.03386 19.1081 9.51014 19.2707 10.0003 19.2707C10.4904 19.2707 10.9667 19.1081 11.3545 18.8084C11.7423 18.5086 12.0196 18.0886 12.1431 17.6143"
              stroke="#005534"
              stroke-width="0.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </g>
          <defs>
            <clipPath id="clip0_2042_429">
              <rect width="20" height="20" fill="white" />
            </clipPath>
          </defs>
        </svg>
        <span v-if="countActivities > 0" class="badge rounded-pill">
          {{ countActivities }}
        </span>
      </router-link>
    </div>
    <!-- /Mobile Notification -->

    <!-- Mobile Button -->
    <a
      id="mobile_btn"
      class="mobile_btn"
      href="#sidebar"
      @click="toggleSidebar1"
    >
      <span class="bar-icon">
        <span></span>
        <span></span>
        <span></span>
      </span>
    </a>
    <!-- /Mobile Button -->
    <div class="header-user">
      <ul class="nav user-menu">
        <!-- Search -->
        <li class="nav-item nav-hello-item me-auto">
          <p>Добро пожаловать в личный кабинет партнера!</p>
        </li>
        <!-- /Search -->

        <!-- Notifications -->
        <li class="nav-item dropdown nav-item-box">
          <a
            href="javascript:void(0);"
            class="nav-link"
            data-bs-toggle="dropdown"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <g clip-path="url(#clip0_2042_429)">
                <path
                  d="M10.0001 0.714294C11.6255 0.714294 13.1843 1.35998 14.3336 2.50931C15.483 3.65864 16.1286 5.21747 16.1286 6.84287C16.1286 13.6572 18.6144 15 19.2858 15H0.714355C1.40007 15 3.8715 13.6429 3.8715 6.84287C3.8715 5.21747 4.51719 3.65864 5.66652 2.50931C6.81585 1.35998 8.37467 0.714294 10.0001 0.714294Z"
                  fill="#005534"
                  stroke="#005534"
                  stroke-width="0.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M7.85742 17.6143C7.98091 18.0886 8.25828 18.5086 8.64607 18.8084C9.03386 19.1081 9.51014 19.2707 10.0003 19.2707C10.4904 19.2707 10.9667 19.1081 11.3545 18.8084C11.7423 18.5086 12.0196 18.0886 12.1431 17.6143"
                  fill="#005534"
                />
                <path
                  d="M7.85742 17.6143C7.98091 18.0886 8.25828 18.5086 8.64607 18.8084C9.03386 19.1081 9.51014 19.2707 10.0003 19.2707C10.4904 19.2707 10.9667 19.1081 11.3545 18.8084C11.7423 18.5086 12.0196 18.0886 12.1431 17.6143"
                  stroke="#005534"
                  stroke-width="0.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_2042_429">
                  <rect width="20" height="20" fill="white" />
                </clipPath>
              </defs>
            </svg>
            <span v-if="countActivities > 0" class="badge rounded-pill">{{
              countActivities
            }}</span>
          </a>
          <div class="dropdown-menu dropdown-menu-end notification-dropdown">
            <div class="topnav-dropdown-header">
              <h4 class="notification-title">Уведомления</h4>
              <a href="javascript:void(0);" class="notification-close"
                ><svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25"
                  height="25"
                  viewBox="0 0 25 25"
                  fill="none"
                >
                  <path
                    d="M19.7918 5.20825L5.2085 19.7916"
                    stroke="#001615"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M19.7918 19.7916L5.2085 5.20825"
                    stroke="#001615"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  /></svg
              ></a>
            </div>
            <div v-if="countActivities > 0" class="noti-content">
              <template
                v-for="(activities, date) in groupedActivities"
                :key="date"
              >
                <!-- Date Badge -->
                <div class="badge badge-status badge-accepted mb-3">
                  {{ date }}
                </div>

                <!-- Notification List -->
                <ul class="notification-list">
                  <li
                    class="notification-message"
                    v-for="item in activities"
                    :key="item.id"
                  >
                    <router-link
                      :to="
                        item.lead_status === 'rejected' ||
                        item.lead_status === 'new'
                          ? `#`
                          : `/deals/${item.lead_id}`
                      "
                      @click="handleSetActivityViewed([item.id])"
                    >
                      <div class="media d-flex">
                        <span class="avatar flex-shrink-0 badge-notification">
                          <i
                            v-if="item.description.includes('rejected')"
                            class="ti ti-x"
                          ></i>
                          <i
                            v-else-if="item.description.includes('accepted')"
                            class="ti ti-check"
                          ></i>
                          <i v-else class="ti ti-bell"> </i>
                        </span>
                        <div class="media-body flex-grow-1">
                          <p class="noti-details">
                            {{
                              item.description
                                .replace("rejected", `"Отклонена"`)
                                .replace("accepted", `"Принята"`)
                                .replace("reserved", `"Забронирована"`)
                                .replace("finished", `"Завершена"`)
                            }}
                          </p>
                          <p class="noti-time">
                            {{ formatDate(item.updated_at) }}
                          </p>
                        </div>
                      </div>
                    </router-link>
                  </li>
                </ul>
              </template>
            </div>
            <div
              v-else
              class="message-empty noti-content"
              :style="{
                height: 'auto',
              }"
            >
              <p>Здесь будут отображаться уведомления.</p>
            </div>

            <div class="topnav-dropdown-footer">
              <router-link to="/activities" class="view-link"
                >Смотреть все
              </router-link>
              <a
                v-if="countActivities > 0"
                href="javascript:void(0);"
                class="clear-link"
                @click="handleSetActivityViewed(allActivityIds)"
                >Очистить все</a
              >
            </div>
          </div>
        </li>
        <!-- /Notifications -->

        <!-- Profile Dropdown -->
        <li class="nav-item dropdown has-arrow main-drop">
          <a href="javascript:void(0);" class="nav-link userset">
            <span class="user-info">
              <span class="user-letter">
                <img
                  src="@/assets/img/profiles/avatar-default.svg"
                  alt="Profile"
                />
              </span>
              <p>{{ authStore?.getUser?.name }}</p>
            </span>
          </a>
        </li>
        <!-- /Profile Dropdown -->
      </ul>
    </div>
  </div>
  <!-- /Header -->
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount, computed } from "vue";
import moment from "moment";
import { useAuthStore } from "@/stores/auth";

const authStore = useAuthStore();
const countActivities = computed(
  () =>
    authStore.getActivities
      .map((item) => item.activities)
      .flat()
      .filter((item) => !item.is_viewed).length
);

const allActivityIds = computed(() =>
  authStore.getActivities
    .map((item) => item.activities)
    .flat()
    .map((item) => item.id)
);

// New Computed Property to Group Activities by Date
const groupedActivities = computed(() => {
  const allActivities = authStore.getActivities
    .flatMap((activity) =>
      activity.activities.map((item) => ({
        ...item,
        lead_id: activity.lead_id, // Preserve lead_id for routing
        lead_status: activity.lead_status,
      }))
    )
    .filter((item) => !item.is_viewed)
    .sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at));

  return allActivities.reduce((groups, activity) => {
    const date = formatDayMM(activity.updated_at);
    if (!groups[date]) {
      groups[date] = [];
    }
    groups[date].push(activity);
    return groups;
  }, {});
});

const isLightMode = ref(true);
const toggleSidebar1 = () => {
  const body = document.body;
  body.classList.toggle("slide-nav");
};

const toggleSidebar = () => {
  const body = document.body;
  body.classList.toggle("mini-sidebar");
};

const initializeDarkMode = () => {
  if (localStorage.getItem("darkMode") === "enabled") {
    enableDarkMode();
  }
};

const enableDarkMode = () => {
  document.documentElement.setAttribute("data-theme", "dark");
  isLightMode.value = false;
  localStorage.setItem("darkMode", "enabled");
};

const disableDarkMode = () => {
  document.documentElement.setAttribute("data-theme", "light");
  isLightMode.value = true;
  localStorage.removeItem("darkMode");
};

const toggleDarkMode = () => {
  if (isLightMode.value) {
    enableDarkMode();
  } else {
    disableDarkMode();
  }
};

const initMouseoverListener = () => {
  document.addEventListener("mouseover", handleMouseover);
};

function formatDayMM(date) {
  return moment(date).locale("ru").format("DD MMM");
}

function formatDate(date) {
  return moment(date).locale("ru").format("DD MMM YYYY, HH:mm");
}

const handleMouseover = (e) => {
  e.stopPropagation();

  const body = document.body;
  const toggleBtn = document.getElementById("toggle_btn");

  if (body.classList.contains("mini-sidebar") && isElementVisible(toggleBtn)) {
    const target = e.target.closest(".sidebar, .header-left");

    if (target) {
      body.classList.add("expand-menu");
      slideDownSubmenu();
    } else {
      body.classList.remove("expand-menu");
      slideUpSubmenu();
    }

    e.preventDefault();
  }
};

const isElementVisible = (element) => {
  return element.offsetWidth > 0 || element.offsetHeight > 0;
};

const slideDownSubmenu = () => {
  const subdropPlusUl = document.getElementsByClassName("subdrop");
  for (let i = 0; i < subdropPlusUl.length; i++) {
    const submenu = subdropPlusUl[i].nextElementSibling;
    if (submenu && submenu.tagName.toLowerCase() === "ul") {
      submenu.style.display = "block";
    }
  }
};

const slideUpSubmenu = () => {
  const subdropPlusUl = document.getElementsByClassName("subdrop");
  for (let i = 0; i < subdropPlusUl.length; i++) {
    const submenu = subdropPlusUl[i].nextElementSibling;
    if (submenu && submenu.tagName.toLowerCase() === "ul") {
      submenu.style.display = "none";
    }
  }
};

const handleSetActivityViewed = async (activityIds) => {
  try {
    const response = await authStore.setActivityViewed(activityIds);
    if (response.status === 200) {
      console.log("success");
    }
  } catch (error) {
    console.error(error);
  }
};

onMounted(() => {
  authStore.fetchActivities();
  initializeDarkMode();
  initMouseoverListener();
});

onBeforeUnmount(() => {
  document.removeEventListener("mouseover", handleMouseover);
});
</script>
