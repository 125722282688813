<template>
  <div class="alert-fixed">
    <span class="ti ti-alert-circle fs-24"></span>
    <div class="alert-fixed-content">
      <slot></slot>
    </div>
  </div>
</template>

<style lang="scss">
.alert-fixed {
  display: flex;
  align-items: center;
  gap: 14px;
  background-color: #fff2f2;
  border-radius: 5px;
  color: #d91414;
  padding: 16px;
  max-width: 470px;
  position: fixed;
  top: 10px;
  left: calc(50% + 16px);
  transform: translateX(calc(-50% - 32px));
  width: calc(100% - 32px);
  margin: 0 16px;
  &-content * {
    font-size: 12px;
    color: #d91414;
  }
}
</style>
