<template>
  <layouts-header></layouts-header>
  <layouts-sidebar></layouts-sidebar>
  <div class="page-wrapper">
    <div class="content">
      <div class="row">
        <div class="col-md-12">
          <!-- Page Header -->
          <page-header :title="title" />
          <!-- /Page Header -->

          <!-- Settings Menu -->
          <settings-tabs></settings-tabs>
          <!-- /Settings Menu -->

          <div class="row">
            <general-sidebar></general-sidebar>

            <div class="col-xl-9 col-lg-12">
              <!-- Settings Info -->
              <div class="card">
                <div class="card-body">
                  <h4 class="fw-semibold mb-3">Данные партнера</h4>
                  <form @submit.prevent="submitForm">
                    <div class="border-bottom mb-3 pb-3">
                      <!-- Employee Name -->
                      <h5 class="fw-semibold mb-1">{{ name }}</h5>
                    </div>
                    <div class="mb-3">
                      <div class="profile-upload">
                        <div class="profile-upload-img">
                          <span><i class="ti ti-photo"></i></span>
                          <img
                            id="ImgPreview"
                            src="@/assets/img/profiles/avatar-02.jpg"
                            alt="img"
                            class="preview1"
                          />
                          <button
                            type="button"
                            id="removeImage1"
                            class="profile-remove"
                          >
                            <i class="feather-x"></i>
                          </button>
                        </div>
                        <div class="profile-upload-content">
                          <label class="profile-upload-btn">
                            <i class="ti ti-file-broken"></i> Загрузить логотип
                            <input type="file" id="imag" class="input-img" />
                          </label>
                          <p>JPG, GIF или PNG. Максимальный размер 800K</p>
                        </div>
                      </div>
                    </div>
                    <div class="border-bottom mb-3">
                      <div class="row">
                        <div class="col-md-4">
                          <div class="mb-3">
                            <label class="form-label">
                              Наименование компании
                              <span class="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              class="form-control"
                              v-model="companyName"
                            />
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="mb-3">
                            <label class="form-label">
                              Менеджер <span class="text-danger">*</span>
                            </label>
                            <input type="text" class="form-control" />
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="mb-3">
                            <label class="form-label">
                              Телефон <span class="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              class="form-control"
                              v-model="phone"
                            />
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="mb-3">
                            <label class="form-label">
                              Email <span class="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              class="form-control"
                              v-model="email"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="border-bottom mb-3 pb-3">
                      <h5 class="fw-semibold mb-1">Адрес</h5>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <div class="mb-3">
                          <label class="form-label">
                            Улица <span class="text-danger">*</span>
                          </label>
                          <input type="text" class="form-control" />
                        </div>
                      </div>
                      <div class="col-lg-3 col-md-6">
                        <div class="mb-3">
                          <label class="form-label">
                            Номер дома <span class="text-danger">*</span>
                          </label>
                          <input type="text" class="form-control" />
                        </div>
                      </div>

                      <div class="col-lg-3 col-md-6">
                        <div class="mb-3">
                          <label class="form-label">
                            Город <span class="text-danger">*</span>
                          </label>
                          <input type="text" class="form-control" />
                        </div>
                      </div>
                      <div class="col-lg-3 col-md-6">
                        <div class="mb-3">
                          <label class="form-label">
                            Почтовый индекс <span class="text-danger">*</span>
                          </label>
                          <input type="text" class="form-control" />
                        </div>
                      </div>
                    </div>
                    <div>
                      <a href="#" class="btn btn-light me-2">Отмена</a>
                      <button type="submit" class="btn btn-primary">
                        Сохранить изменения
                      </button>
                    </div>
                  </form>
                </div>
              </div>
              <!-- /Settings Info -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref } from "vue";
import { useRouter } from "vue-router";
import { useAuthStore } from "@/stores/auth";

const authStore = useAuthStore();
const router = useRouter();
const title = ref("Настройки профиля");

const name = ref(authStore.getUser?.name || "");
const companyName = ref(authStore.getUser?.company || "");
const phone = ref(authStore.getUser?.phone || "");
const email = ref(authStore.getUser?.email || "");

const submitForm = async () => {
  if (!companyName.value || !phone.value || !email.value) {
    return;
  }
  const data = {
    company: companyName.value,
    phone: phone.value,
    email: email.value,
  };

  try {
    const response = await authStore.setUser(data);
    console.log(response.data);
    if (response.status === 201) {
      console.log("success");
    }
  } catch (error) {
    console.error("Error setting user:", error);
  }
};
</script>
