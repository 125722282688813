import { useAuthStore } from "@/stores/auth";
import { useDealStore } from "@/stores/deal";
import { useRequestsStore } from "@/stores/requests";
import { baseUrl } from "@/config";
import { useMessagesStore } from "@/stores/messages";
import { onBeforeUnmount, ref, watch } from "vue";
import { useRoute } from "vue-router";
import sound from "../assets/sounds/message.mp3";

let eventSource = null;
let reconnectInterval = null;
let isEventSourceOpen = false;

export const startEventSource = () => {
  if (isEventSourceOpen) return;

  const authStore = useAuthStore();
  const dealStore = useDealStore();
  const messagesStore = useMessagesStore();
  const requestsStore = useRequestsStore();
  const route = useRoute();
  let audioEnabled = ref(authStore.getIsSoundActive);
  watch(
    () => authStore.getIsSoundActive,
    (newValue) => {
      audioEnabled.value = newValue;
    }
  );
  const playAudio = () => {
    if (audioEnabled.value) {
      const audio = new Audio(sound);
      audio.play().catch((error) => {
        console.error("Ошибка воспроизведения звука:", error);
      });
    }
  };

  if (!authStore.isAuthenticated) return;
  eventSource = new EventSource(`${baseUrl}/sse?token=${authStore.getToken}`);

  eventSource.onopen = () => {
    console.log("Connection to server opened.");
    clearInterval(reconnectInterval);
    isEventSourceOpen = true;
  };

  eventSource.addEventListener(
    dealStore.getDeal?.fr_lk?.uuid,
    async (event) => {
      const parsedData = JSON.parse(event.data);
      const chat = {
        id: parsedData.chat_id,
        partner_id: parsedData.partner_id,
        manager_id: parsedData.manager_id,
      };

      if (Object.keys(chat).length > 0) {
        console.log("chat", chat);
        playAudio();
        await messagesStore.fetchChats(
          dealStore.getDeal.lead_id,
          chat.manager_id
        );
      }

      const activity = {
        activity_history_id: parsedData.activity_history_id,
        partner_id: parsedData.partner_id,
      };
      if (Object.keys(activity).length > 0) {
        await authStore.fetchActivities();
        if (dealStore.getDeal) {
          await dealStore.fetchDeal(dealStore.getDeal.id);
        }
      }

      const request = {
        partner_id: parsedData.partner_id,
        bid: parsedData.bid,
      };

      if (Object.keys(request).length > 0) {
        await requestsStore.fetchRequests();
      }
    }
  );

  eventSource.onerror = (err) => {
    console.error("SSE error:", err);
    eventSource.close();
    isEventSourceOpen = false;

    reconnectInterval = setInterval(() => {
      console.log("Attempting to reconnect...");
      clearInterval(reconnectInterval);
      startEventSource();
    }, 5000);
  };
};

onBeforeUnmount(() => {
  if (eventSource) {
    eventSource.close();
  }
  if (reconnectInterval) {
    clearInterval(reconnectInterval);
  }
});
