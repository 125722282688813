<template>
  <div class="">
    <div class="main-wrapper">
      <layouts-header></layouts-header>
      <layouts-sidebar :is-admin="true"></layouts-sidebar>

      <div class="page-wrapper">
        <div class="page-analytics">
          <!-- Page Header -->
          <page-header :title="title" :breadcrumb="breadcrumb" />
          <!-- /Page Header -->

          <div class="page-content">
            <div class="row g-2">
              <div class="col-md-6 g-2">
                <div class="row g-2">
                  <div class="col-md-6">
                    <dashboard-stat
                      title="Aктивные сделки"
                      :value="activeDealsCount"
                      type="activeDeals"
                    />
                  </div>
                  <div class="col-md-6">
                    <dashboard-stat
                      title="Общее количество  сделок"
                      :value="allDealsCount"
                      type="allDeals"
                    />
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-md-12">
                    <div class="card flex-fill mb-2">
                      <div class="card-header border-0 pb-0">
                        <div
                          class="d-flex align-items-center justify-content-between flex-wrap row-gap-3"
                        >
                          <h4>
                            <i class="ti ti-grip-vertical me-1"></i>Статистика
                            по заявкам
                          </h4>
                          <div class="dropdown">
                            <a
                              class="dropdown-toggle d-flex align-items-center gap-2"
                              data-bs-toggle="dropdown"
                              href="javascript:void(0);"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="14"
                                height="14"
                                viewBox="0 0 14 14"
                                fill="none"
                              >
                                <g clip-path="url(#clip0_2088_5941)">
                                  <path
                                    d="M6 6.5H7V11"
                                    stroke="#000001"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                  <path
                                    d="M5.5 11H8.5"
                                    stroke="#000001"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                  <path
                                    d="M1.5 2.5C1.23478 2.5 0.98043 2.60536 0.792893 2.79289C0.605357 2.98043 0.5 3.23478 0.5 3.5V12.5C0.5 12.7652 0.605357 13.0196 0.792893 13.2071C0.98043 13.3946 1.23478 13.5 1.5 13.5H12.5C12.7652 13.5 13.0196 13.3946 13.2071 13.2071C13.3946 13.0196 13.5 12.7652 13.5 12.5V3.5C13.5 3.23478 13.3946 2.98043 13.2071 2.79289C13.0196 2.60536 12.7652 2.5 12.5 2.5H10.5"
                                    stroke="#000001"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                  <path
                                    d="M3.5 0.5V4.5"
                                    stroke="#000001"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                  <path
                                    d="M10.5 0.5V4.5"
                                    stroke="#000001"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                  <path
                                    d="M3.5 2.5H8.5"
                                    stroke="#000001"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                </g>
                                <defs>
                                  <clipPath id="clip0_2088_5941">
                                    <rect width="14" height="14" fill="white" />
                                  </clipPath>
                                </defs>
                              </svg>
                              <p>
                                {{
                                  getDateRange("statsByLeads").endDate -
                                    getDateRange("statsByLeads").startDate >
                                  30 * 24 * 60 * 60 * 1000
                                    ? "За всё время"
                                    : getDateRange(
                                        "statsByLeads"
                                      ).startDate.toLocaleDateString("ru-RU", {
                                        month: "short",
                                        day: "numeric",
                                      }) +
                                      " - " +
                                      getDateRange(
                                        "statsByLeads"
                                      ).endDate.toLocaleDateString("ru-RU", {
                                        month: "short",
                                        day: "numeric",
                                      })
                                }}
                              </p>
                            </a>
                            <div class="dropdown-menu dropdown-menu-end">
                              <a
                                href="javascript:void(0);"
                                class="dropdown-item"
                                @click="handleLeadsDateRange(365)"
                              >
                                За всё время
                              </a>
                              <a
                                href="javascript:void(0);"
                                class="dropdown-item"
                                @click="handleLeadsDateRange(30)"
                              >
                                За 30 Дней
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <template v-if="!isLoading('statsByLeads')">
                        <div class="card-body">
                          <div class="donut-chart-wrapper">
                            <div class="donut-chart">
                              <apexchart
                                type="donut"
                                height="300"
                                :options="{
                                  chart: {
                                    height: 350,
                                    type: 'donut',
                                    toolbar: {
                                      show: false,
                                    },
                                  },
                                  legend: {
                                    show: false,
                                  },
                                  dataLabels: {
                                    enabled: true,
                                  },
                                  labels: leadsStatusChart.labels.map(
                                    (label) => statusMapping[label] || label
                                  ),
                                  colors: [
                                    '#edb95f',
                                    '#bebebe',
                                    '#216b4e',
                                    '#005534',
                                    '#005534',
                                    '#bebebe',
                                  ],
                                  plotOptions: {
                                    pie: {
                                      customScale: 0.8,
                                    },
                                  },
                                  stroke: {
                                    width: 0,
                                  },
                                }"
                                :series="leadsStatusChart.values"
                              ></apexchart>
                            </div>
                            <div class="donut-chart">
                              <apexchart
                                type="donut"
                                height="300"
                                :options="{
                                  chart: {
                                    height: 350,
                                    type: 'donut',
                                    toolbar: {
                                      show: false,
                                    },
                                  },
                                  legend: {
                                    show: false,
                                  },
                                  dataLabels: {
                                    enabled: true,
                                  },
                                  labels: leadsTypeChart.labels,
                                  colors: ['#bebebe', '#d0d0d0'],
                                  plotOptions: {
                                    pie: {
                                      customScale: 0.75,
                                    },
                                  },
                                  stroke: {
                                    width: 0,
                                  },
                                }"
                                :series="leadsTypeChart.values"
                              ></apexchart>
                            </div>
                          </div>
                        </div>
                      </template>
                      <div v-else>
                        <div
                          class="card-body pt-0"
                          :style="{
                            height: '350px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }"
                        >
                          <div
                            class="d-flex justify-content-center align-items-center"
                          >
                            <div class="spinner-border" role="status"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <won-chart></won-chart>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="col-md-12 d-flex">
                  <history-chart></history-chart>
                </div>
                <div class="col-md-12 d-flex">
                  <deals-chart></deals-chart>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="page-footer">
          <main-footer></main-footer>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted } from "vue";
import { useStatistics } from "@/composables/useStatistics";

const title = "Главная";
const breadcrumb = [
  {
    title: "Главная",
    link: "/admin/analytics",
  },
];

const statusMapping = {
  new: "Новая",
  rejected: "Отклонена",
  accepted: "Принята",
  completed: "Завершена",
  closed: "Закрыта",
  finished: "Завершена",
  reserved: "Забронирована",
};

const {
  fetchAllStats,
  activeDealsCount,
  allDealsCount,
  leadsStatusChart,
  leadsTypeChart,
  getDateRange,
  updateDateRange,
  isLoading,
} = useStatistics();

onMounted(async () => {
  await fetchAllStats();
});

const handleLeadsDateRange = (days) => {
  updateDateRange(
    "statsByLeads",
    new Date(Date.now() - days * 24 * 60 * 60 * 1000),
    new Date()
  );
};
</script>

<style scoped lang="scss">
.donut-chart-wrapper {
  display: grid;
  grid-template-columns: 1fr;
  gap: 8px;
  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }
}

.donut-chart {
  position: relative;
  &:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 244px;
    height: 244px;
    background-color: #f3f3f3;
    border-radius: 50%;
    z-index: 0;
  }
  &:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 94px;
    height: 94px;
    background-color: #fff;
    border-radius: 50%;
    z-index: 1;
  }
}

.card-header {
  h4 {
    font-size: 16px;
  }
}

.card .card-body {
  @media screen and (max-width: 992px) {
    padding: 16px 0;
  }
}

.dropdown {
  p {
    font-size: 12px;
  }
}
</style>
