import { defineStore } from "pinia";
import apiClient from "@/axios";
import { baseUrl } from "@/config";

export const useMessagesStore = defineStore("messages", {
  state: () => ({
    messages: [],
  }),
  getters: {
    getMessages: (state) => state.messages,
  },
  actions: {
    addMessage(message) {
      this.messages.push(message);
    },
    readMessage(id) {
      this.messages = this.messages.map((message) =>
        message.id === id ? { ...message, status: "read" } : message
      );
    },
    clearMessages() {
      this.messages = [];
    },
    async fetchChats(leadId, mangerId) {
      try {
        const response = await apiClient.get(
          `${baseUrl}/chats/${leadId}/${mangerId}`
        );
        this.messages = response?.data?.messages?.sort(
          (a, b) => new Date(a.created_at) - new Date(b.created_at)
        );

        return response;
      } catch (error) {
        console.log(error);
      }
    },
    async sendMessage(leadId, mangerId, message, files = []) {
      const response = await apiClient.post(`${baseUrl}/chats/${leadId}/`, {
        managerId: mangerId,
        message: message,
        files: files,
      });
      this.messages.push(response.data);
      setTimeout(async () => {
        await this.fetchChats(leadId, mangerId);
      }, 500);
      return response;
    },
    async readMessages(leadId, mangerId, ids) {
      const response = await apiClient.post(
        `${baseUrl}/chats/${leadId}/${mangerId}`,
        {
          ids: ids,
        }
      );
      return response;
    },
    async uploadFiles(formData) {
      try {
        const response = await apiClient.post(
          `${baseUrl}/chats/files`,
          formData
        );
        return response;
      } catch (error) {
        console.error("Error uploading files:", error);
      }
    },
    async deleteFile(ids) {
      try {
        const files = [];
        files.push(ids);
        const response = await apiClient.post(`${baseUrl}/file/delete`, {
          ids: files,
        });
        return response;
      } catch (error) {
        console.error("Error uploading files:", error);
      }
    },
  },
});
