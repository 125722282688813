<template>
  <div class="choose-two-factor-wrapper">
    <div class="choose-two-factor">
      <div class="card">
        <div class="card-body">
          <div class="text-center mb-0 login-logo">
            <img src="@/assets/img/logo.svg" class="img-fluid" alt="Logo" />
          </div>
          <p class="mb-0 fs-18 fw-medium text-center">
            Для входа в ваш аккаунт выберите способ подтверждения:
          </p>
          <div class="d-flex justify-content-center gap-1">
            <router-link
              to="/verification-email"
              class="btn btn-primary login-btn"
              >Через Email</router-link
            >
            <router-link
              to="/verification-sms"
              class="btn btn-outline-primary login-btn"
              >Через SMS</router-link
            >
          </div>
        </div>
      </div>
    </div>
    <contacts-group />
  </div>
</template>

<style lang="scss" scoped>
.choose-two-factor {
  max-width: 498px;
  .card {
    &-body {
      padding: 52px 60px;
      display: flex;
      flex-direction: column;
      justify-content: start;
      gap: 52px;
      .login-btn {
        width: 100%;
      }
    }
  }
  p {
    color: #001615;
  }
}
</style>
