<template>
  <div class="register-form">
    <VForm
      @submit="onSubmit"
      :validation-schema="schema"
      v-slot="{ errors }"
      class="flex-fill"
    >
      <div class="mx-auto">
        <div class="text-center mb-4 login-logo">
          <img src="@/assets/img/logo.svg" class="img-fluid" alt="Logo" />
        </div>
        <div class="mb-10">
          <p class="login-title">Регистрация на портале «GCAP Лизинг»</p>
        </div>

        <div class="row">
          <div class="col-6">
            <div class="mb-3 text-truncate">
              <label class="col-form-label d-inline"
                >Наименование компании</label
              >
              <div class="position-relative" :style="{ paddingTop: '5px' }">
                <Field
                  name="companyName"
                  type="text"
                  v-model="form.companyName"
                  class="form-control"
                  placeholder="ООО “Пример”"
                  :class="{ 'is-invalid': errors.companyName }"
                />
                <div class="invalid-feedback">{{ errors.companyName }}</div>
                <div class="emailshow text-danger" id="companyName">
                  {{ companyNameError }}
                </div>
              </div>
            </div>
            <div class="mb-3 text-truncate">
              <label class="col-form-label d-inline">ИНН компании</label>
              <div class="pass-group">
                <div class="position-relative" :style="{ paddingTop: '5px' }">
                  <Field
                    name="inn"
                    type="text"
                    v-model="form.inn"
                    class="form-control"
                    placeholder="000000000"
                    :class="{ 'is-invalid': errors.inn }"
                  />
                  <div class="invalid-feedback">{{ errors.inn }}</div>
                  <div class="emailshow text-danger" id="inn">
                    {{ innError }}
                  </div>
                </div>
              </div>
            </div>
            <div class="mb-3 text-truncate">
              <label class="col-form-label d-inline">Email</label>
              <div class="position-relative" :style="{ paddingTop: '5px' }">
                <Field
                  name="email"
                  type="text"
                  v-model="form.email"
                  class="form-control"
                  placeholder="Введите email"
                  :class="{ 'is-invalid': errors.email }"
                />
                <div class="invalid-feedback">{{ errors.email }}</div>
                <div class="emailshow text-danger" id="email">
                  {{ emailError }}
                </div>
              </div>
            </div>
          </div>

          <div class="col-6">
            <div class="mb-3 text-truncate">
              <label class="col-form-label d-inline">Юридический адрес</label>
              <div class="position-relative" :style="{ paddingTop: '5px' }">
                <Field
                  name="legalAddress"
                  type="text"
                  v-model="form.legalAddress"
                  class="form-control"
                  placeholder="Укажите адрес"
                  :class="{ 'is-invalid': errors.legalAddress }"
                />
                <div class="invalid-feedback">
                  {{ errors.legalAddress }}
                </div>
                <div class="emailshow text-danger" id="legalAddress">
                  {{ legalAddressError }}
                </div>
              </div>
            </div>
            <div class="mb-3 text-truncate">
              <label class="col-form-label d-inline">ФИО Представителя</label>
              <div class="pass-group">
                <div class="position-relative" :style="{ paddingTop: '5px' }">
                  <Field
                    name="representativeName"
                    type="text"
                    v-model="form.representativeName"
                    class="form-control"
                    placeholder="Введите ФИО"
                    :class="{ 'is-invalid': errors.representativeName }"
                  />
                  <div class="invalid-feedback">
                    {{ errors.representativeName }}
                  </div>
                  <div class="emailshow text-danger" id="representativeName">
                    {{ representativeNameError }}
                  </div>
                </div>
              </div>
            </div>
            <div class="mb-3 text-truncate">
              <label class="col-form-label d-inline">Должность</label>
              <div class="position-relative" :style="{ paddingTop: '5px' }">
                <Field
                  name="position"
                  type="text"
                  v-model="form.position"
                  class="form-control"
                  placeholder="Укажите должность"
                  :class="{ 'is-invalid': errors.position }"
                />
                <div class="invalid-feedback">{{ errors.position }}</div>
                <div class="emailshow text-danger" id="position">
                  {{ positionError }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <label class="checkboxs">
          <input type="checkbox" />
          <span class="checkmarks"></span>
          <span class="check-text"
            >Отправляя заявку, я даю согласие на обработку персональных данных.
          </span>
        </label>

        <div class="mt-8 mb-4">
          <button
            type="submit"
            class="btn btn-primary w-100 register-btn"
            :disabled="isSubmitting"
          >
            Зарегистрироваться
          </button>
        </div>

        <div class="text-center mt-4">
          Уже зарегистрированы?
          <router-link to="/" class="text-primary fw-medium link-hover-reverse"
            >Войти в аккаунт</router-link
          >
        </div>
      </div>
    </VForm>
    <contacts-group />
  </div>
</template>

<script>
import { ref } from "vue";
import { useAuthStore } from "@/stores/auth";
import { Form as VForm, Field } from "vee-validate";
import * as Yup from "yup";
import { useRouter } from "vue-router";
import { startEventSource } from "@/services/sseService";
export default {
  components: {
    VForm,
    Field,
  },
  setup() {
    const authStore = useAuthStore();
    const router = useRouter();
    const emailError = ref("");
    const companyNameError = ref("");
    const innError = ref("");
    const legalAddressError = ref("");
    const representativeNameError = ref("");
    const positionError = ref("");
    const isSubmitting = ref(false);

    const form = ref({
      companyName: "",
      inn: "",
      email: "",
      legalAddress: "",
      representativeName: "",
      position: "",
    });

    const schema = Yup.object().shape({
      email: Yup.string().required("Email обязателен"),
      companyName: Yup.string().required("Наименование компании обязательно"),
      inn: Yup.string().required("ИНН компании обязателен"),
      legalAddress: Yup.string().required("Юридический адрес обязателен"),
      representativeName: Yup.string().required(
        "ФИО Представителя обязательно"
      ),
      position: Yup.string().required("Должность обязательна"),
    });

    const onSubmit = async (values) => {
      emailError.value = "";
      companyNameError.value = "";
      innError.value = "";
      legalAddressError.value = "";
      representativeNameError.value = "";
      positionError.value = "";
      isSubmitting.value = true;

      try {
        await authStore.register(values);
        router.push("/deals");
        startEventSource();
      } catch (error) {
        if (error.response) {
          const { status, data } = error.response;
          if (status === 400) {
            emailError.value = data.message || "Некорректные данные";
          } else {
            console.error("Unexpected error:", data);
          }
        } else {
          console.error("Error connecting to the server:", error);
        }
      } finally {
        isSubmitting.value = false;
      }
    };

    return {
      schema,
      onSubmit,
      emailError,
      companyNameError,
      innError,
      legalAddressError,
      representativeNameError,
      positionError,
      form,
      isSubmitting,
    };
  },
};
</script>
