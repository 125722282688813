<template>
  <div class="card w-100">
    <div class="card-header border-0 pb-0">
      <div
        class="d-flex align-items-center justify-content-between flex-wrap row-gap-3"
      >
        <h4><i class="ti ti-grip-vertical me-1"></i>Динамика заявок</h4>
        <div class="d-flex align-items-center flex-wrap row-gap-2">
          <div class="dropdown">
            <a
              class="dropdown-toggle d-flex align-items-center gap-2"
              data-bs-toggle="dropdown"
              href="javascript:void(0);"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
              >
                <g clip-path="url(#clip0_2088_5941)">
                  <path
                    d="M6 6.5H7V11"
                    stroke="#000001"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M5.5 11H8.5"
                    stroke="#000001"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M1.5 2.5C1.23478 2.5 0.98043 2.60536 0.792893 2.79289C0.605357 2.98043 0.5 3.23478 0.5 3.5V12.5C0.5 12.7652 0.605357 13.0196 0.792893 13.2071C0.98043 13.3946 1.23478 13.5 1.5 13.5H12.5C12.7652 13.5 13.0196 13.3946 13.2071 13.2071C13.3946 13.0196 13.5 12.7652 13.5 12.5V3.5C13.5 3.23478 13.3946 2.98043 13.2071 2.79289C13.0196 2.60536 12.7652 2.5 12.5 2.5H10.5"
                    stroke="#000001"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M3.5 0.5V4.5"
                    stroke="#000001"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M10.5 0.5V4.5"
                    stroke="#000001"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M3.5 2.5H8.5"
                    stroke="#000001"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_2088_5941">
                    <rect width="14" height="14" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              <p>
                {{
                  getDateRange("newLeads").endDate -
                    getDateRange("newLeads").startDate >
                  30 * 24 * 60 * 60 * 1000
                    ? "За всё время"
                    : getDateRange("newLeads").startDate.toLocaleDateString(
                        "ru-RU",
                        { month: "short", day: "numeric" }
                      ) +
                      " - " +
                      getDateRange("newLeads").endDate.toLocaleDateString(
                        "ru-RU",
                        { month: "short", day: "numeric" }
                      )
                }}
              </p>
            </a>
            <div class="dropdown-menu dropdown-menu-end">
              <a
                href="javascript:void(0);"
                class="dropdown-item"
                @click="handleNewLeadsDateRange(365)"
              >
                За всё время
              </a>
              <a
                href="javascript:void(0);"
                class="dropdown-item"
                @click="handleNewLeadsDateRange(30)"
              >
                За 30 дней
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <template v-if="!isLoading('newLeads')">
      <div class="card-body">
        {{ dealsYear.series.value[0].data.x }}
        <br />
        {{ dealsYear.series.value[0].data.y }}
        <div id="deals-year">
          <template v-if="dealsYear.series.value[0].data.length > 0">
            <apexchart
              type="area"
              height="273"
              :options="dealsYear.chart"
              :series="dealsYear.series.value"
            ></apexchart>
          </template>
          <template v-else>
            <div
              class="d-flex justify-content-center align-items-center"
              style="height: 273px"
            >
              <p>Нет данных за выбранный период</p>
            </div>
          </template>
        </div>
      </div>
    </template>
    <template v-else>
      <div
        class="card-body pt-0"
        :style="{
          height: '273px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }"
      >
        <div class="d-flex justify-content-center align-items-center">
          <div class="spinner-border" role="status"></div>
        </div>
      </div>
    </template>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { useStatistics } from "@/composables/useStatistics";

const { newLeadsStats, updateDateRange, getDateRange, isLoading } =
  useStatistics();

const dealsYear = {
  series: ref([
    {
      name: "Заявок",
      data: newLeadsStats.value?.map((item) => ({
        x: item.day,
        y: item.qnt,
      })),
    },
  ]),
  chart: {
    height: 273,
    type: "area",
    zoom: { enabled: false },
    colors: ["#005534"],
    dataLabels: { enabled: false },
    stroke: { curve: "straight", width: 2 },
    title: { text: "", align: "left" },
    yaxis: {
      labels: { formatter: (val) => val },
    },
    legend: { position: "top", horizontalAlign: "left" },
    xaxis: {
      type: "datetime",
    },
  },
};

const handleNewLeadsDateRange = async (days) => {
  const end = new Date();
  const start = new Date(Date.now() - days * 24 * 60 * 60 * 1000);
  await updateDateRange("newLeads", start, end);

  const newData = newLeadsStats.value?.map((item) => ({
    x: item.day,
    y: item.qnt,
  }));

  dealsYear.series.value[0].data = newData;
};

onMounted(async () => {
  await handleNewLeadsDateRange(365);
});
</script>

<style scoped lang="scss">
.dropdown {
  p {
    font-size: 12px;
  }
}
</style>
