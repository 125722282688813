<template>
  <div id="app">
    <div class="main-wrapper">
      <router-view />
    </div>
  </div>
</template>
<script setup>
import { onMounted } from "vue";
import { startEventSource } from "@/services/sseService";
import { useAuthStore } from "@/stores/auth";

const authStore = useAuthStore();

onMounted(() => {
  if (authStore.isAuthenticated) {
    startEventSource();
  }
});
</script>
