<template>
  <div class="dashboard-stat">
    <div class="dashboard-stat__heading">
      <h5>{{ title }}</h5>
      <div class="dropdown">
        <a
          class="dropdown-toggle d-flex align-items-center gap-2"
          data-bs-toggle="dropdown"
          href="javascript:void(0);"
        >
          <p>
            {{
              getDateRange(props.type).endDate -
                getDateRange(props.type).startDate >
              30 * 24 * 60 * 60 * 1000
                ? "За всё время"
                : getDateRange(props.type).startDate.toLocaleDateString(
                    "ru-RU",
                    { month: "short", day: "numeric" }
                  ) +
                  " - " +
                  getDateRange(props.type).endDate.toLocaleDateString("ru-RU", {
                    month: "short",
                    day: "numeric",
                  })
            }}
          </p>
        </a>
        <div class="dropdown-menu">
          <a
            href="javascript:void(0);"
            class="dropdown-item dropmenu-item-primary"
            @click="handleHistoryDateRange(365)"
          >
            За всё время
          </a>
          <a
            href="javascript:void(0);"
            class="dropdown-item dropmenu-item-primary"
            @click="handleHistoryDateRange(30)"
          >
            За 30 Дней
          </a>
        </div>
      </div>
    </div>
    <div class="dashboard-stat__value">
      <p>{{ value }}</p>
      <span>
        {{ percentageChange.toFixed(0) }}%
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="13"
          height="7"
          viewBox="0 0 13 7"
          fill="none"
          :style="{
            transform: `scaleY(${percentageChange >= 0 ? 1 : -1})`,
          }"
        >
          <path d="M0.267578 7L6.19541 0 L12.1232 7" fill="white" />
        </svg>
      </span>
    </div>
  </div>
</template>

<script setup>
import { useStatistics } from "@/composables/useStatistics";
import { computed } from "vue";
const {
  updateDateRange,
  getDateRange,
  allDealsCount,
  activeDealsCount,
  allDealsCountMax,
  activeDealsCountMax,
} = useStatistics();

const props = defineProps({
  title: String,
  value: String,
  type: String,
});

const selectedValue =
  props.type === "allDeals"
    ? allDealsCount
    : props.type === "activeDeals"
    ? activeDealsCount
    : 0;
const historicalValue =
  props.type === "allDeals"
    ? allDealsCountMax
    : props.type === "activeDeals"
    ? activeDealsCountMax
    : 0;

const handleHistoryDateRange = async (days) => {
  const end = new Date();
  const start = new Date(Date.now() - days * 24 * 60 * 60 * 1000);
  await updateDateRange(props.type, start, end);
};

const percentageChange = computed(() => {
  return (
    ((selectedValue.value - historicalValue.value) / historicalValue.value) *
    100
  );
});
</script>

<style scoped lang="scss">
.dashboard-stat {
  border-radius: 5px;
  background: #005534;
  color: #fff;
  padding: 12px 16px;
  height: 100%;
  &__heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    h5 {
      color: var(--White, var(--white-100, #fff));
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
    .dropdown {
      > a {
        color: var(--White, var(--white-100, #fff));
        padding: 6px 9px;
        font-size: 10px;
        line-height: 10px;
        &:hover {
          background-color: transparent;
          border-color: #fff;
        }
      }
      &-toggle {
        &:after {
          border-right-color: #fff;
          border-bottom-color: #fff;
          margin-top: -3px;
        }
      }
      .dropdown-toggle {
        box-shadow: none;
        border-radius: 3px;
        border: 0.5px solid rgba(242, 242, 242, 0.17);
      }
    }
  }
  &__value {
    display: flex;
    align-items: center;
    justify-content: start;
    margin-top: 40px;
    gap: 16px;
    p {
      color: var(--white-100, #fff);
      font-size: 30px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin-bottom: 0;
    }
    span {
      display: flex;
      align-items: center;
      gap: 6px;
      color: var(--white-100, #fff);
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      border-radius: 3px;
      background: rgba(255, 255, 255, 0.13);
      height: 28px;
      padding: 0 6px;
    }
  }
}
</style>
