// src/stores/auth.js
import { defineStore } from "pinia";
import apiClient from "@/axios";
import { baseUrl } from "@/config";
import { useRequestsStore } from "@/stores/requests";

export const useAuthStore = defineStore("auth", {
  state: () => ({
    accessToken: null,
    refreshToken: null,
    user: null, // Optional: user information
    activities: [],
    currentManagerID: null,
    isSoundActive: true,
  }),
  getters: {
    getUser: (state) => state.user,
    getActivities: (state) => state.activities,
    getCurrentManagerID: (state) => state.currentManagerID,
    isAuthenticated: (state) => !!state.accessToken,
    getToken: (state) => state.accessToken,
    getIsSoundActive: (state) => state.isSoundActive,
  },
  actions: {
    setAccessToken(token) {
      this.accessToken = token;
    },
    setIsSoundActive(value) {
      this.isSoundActive = value;
    },
    async login(login, password) {
      const response = await apiClient.post(`${baseUrl}/auth/login`, {
        login,
        password,
      });

      const { access_token, refresh_token } = response.data;

      this.setAccessToken(access_token);
      this.refreshToken = refresh_token;

      // After successful login, fetch user information
      await this.fetchUser();
    },

    async refreshToken() {
      const response = await apiClient.post(`${baseUrl}/auth/refresh-token`);
      const { access_token } = response.data;

      this.setAccessToken(access_token);

      return access_token;
    },

    async logout() {
      try {
        await apiClient.get(`${baseUrl}/auth/logout`);
      } catch (error) {
        console.error("Error during logout:", error);
      } finally {
        this.setAccessToken(null);
        this.refreshToken = null;
        this.user = null;
        this.activities = [];
        this.currentManagerID = null;
        useRequestsStore().clearRequestsStore();
      }
    },

    // Optional: Fetch user information
    async fetchUser() {
      if (!this.isAuthenticated) return;
      try {
        const response = await apiClient.get(`${baseUrl}/auth/me`);
        this.user = response.data;
      } catch (error) {
        console.error("Error fetching user:", error);
      }
    },

    async fetchActivities(accountId = this.user?.id) {
      const response = await apiClient.get(
        `${baseUrl}/activity-histories/accounts/${accountId}`
      );
      this.activities = response.data;
    },

    async setUser(data) {
      try {
        const response = await apiClient.put(`${baseUrl}/accounts/`, data);
        this.user = response.data;
        return response.data;
      } catch (error) {
        console.error("Error setting user:", error);
      }
    },

    async setActivityViewed(activityIds) {
      const response = await apiClient.post(
        `${baseUrl}/activity-histories/viewed`,
        {
          activityIds: activityIds,
        }
      );
      await this.fetchActivities();
      return response;
    },
    setCurrentManagerID(managerId) {
      this.currentManagerID = managerId;
    },
  },
  persist: {
    enabled: true,
    storage: sessionStorage,
  },
});
