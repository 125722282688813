import { defineStore } from "pinia";
import apiClient from "@/axios";
import { baseUrl } from "@/config";

export const useStatisticsStore = defineStore("statistics", {
  state: () => ({
    activeDealsCount: 0,
    allDealsCount: 0,
    allDealsCountMax: 0,
    activeDealsCountMax: 0,
    newLeadsStats: [],
    leadsHistory: [],
    commissionStats: null,
    leadsStatusChart: {
      labels: [],
      values: [],
    },
    leadsTypeChart: {
      labels: [],
      values: [],
    },
    dateRanges: {
      activeDeals: {
        startDate: new Date(Date.now() - 365.1 * 24 * 60 * 60 * 1000),
        endDate: new Date(),
      },
      allDeals: {
        startDate: new Date(Date.now() - 365 * 24 * 60 * 60 * 1000),
        endDate: new Date(),
      },
      newLeads: {
        startDate: new Date(Date.now() - 365 * 24 * 60 * 60 * 1000),
        endDate: new Date(),
      },
      history: {
        startDate: new Date(Date.now() - 365 * 24 * 60 * 60 * 1000),
        endDate: new Date(),
      },
      commission: {
        startDate: new Date(Date.now() - 365 * 24 * 60 * 60 * 1000),
        endDate: new Date(),
      },
      statsByLeads: {
        startDate: new Date(Date.now() - 365 * 24 * 60 * 60 * 1000),
        endDate: new Date(),
      },
    },
    loading: {
      activeDeals: false,
      allDeals: false,
      newLeads: false,
      history: false,
      commission: false,
      statsByLeads: false,
    },
    error: null,
  }),

  actions: {
    async fetchActiveDealsCount() {
      try {
        this.loading.activeDeals = true;
        const response = await apiClient.post(
          `${baseUrl}/bids/statistic/history`,
          {
            startDate: this.dateRanges.activeDeals.startDate,
            endDate: this.dateRanges.activeDeals.endDate,
          }
        );
        this.activeDealsCount = response.data.statistics_leadhistory.reduce(
          (count, doc) => {
            const validLeads = doc.leads.filter(
              (lead) =>
                lead.status === "new" ||
                lead.status === "accepted" ||
                lead.status === "reserved"
            );
            return count + validLeads.length;
          },
          0
        );
        if (
          this.activeDealsCount > this.activeDealsCountMax ||
          this.activeDealsCountMax === 0
        ) {
          this.activeDealsCountMax = this.activeDealsCount;
        }
      } catch (err) {
        this.error = err.message;
      } finally {
        this.loading.activeDeals = false;
      }
    },
    async fetchAllDealsCount() {
      try {
        this.loading.allDeals = true;
        const response = await apiClient.post(
          `${baseUrl}/bids/statistic/history`,
          {
            startDate: this.dateRanges.allDeals.startDate,
            endDate: this.dateRanges.allDeals.endDate,
          }
        );
        this.allDealsCount = response.data.statistics_leadhistory.reduce(
          (count, doc) => count + doc.leads.length,
          0
        );
        if (
          this.allDealsCount > this.allDealsCountMax ||
          this.allDealsCountMax === 0
        ) {
          this.allDealsCountMax = this.allDealsCount;
        }
      } catch (err) {
        this.error = err.message;
      } finally {
        this.loading.allDeals = false;
      }
    },
    async fetchNewLeadsStats() {
      try {
        this.loading.newLeads = true;
        this.newLeadsStats = [];
        const response = await apiClient.post(`${baseUrl}/bids/statistic/new`, {
          startDate: this.dateRanges.newLeads.startDate,
          endDate: this.dateRanges.newLeads.endDate,
        });
        this.newLeadsStats = response.data.statistics_qnt;
      } catch (err) {
        this.error = err.message;
      } finally {
        this.loading.newLeads = false;
      }
    },

    async initialFetchLeadsHistory() {
      try {
        this.leadsHistory = [];
        this.loading.history = true;
        const response = await apiClient.post(
          `${baseUrl}/bids/statistic/history`,
          {
            startDate: this.dateRanges.history.startDate,
            endDate: this.dateRanges.history.endDate,
          }
        );
        this.leadsHistory = response.data.statistics_leadhistory;

        this.activeDealsCount = response.data.statistics_leadhistory.reduce(
          (count, doc) => {
            const validLeads = doc.leads.filter(
              (lead) =>
                lead.status === "new" ||
                lead.status === "accepted" ||
                lead.status === "reserved"
            );
            return count + validLeads.length;
          },
          0
        );
        if (
          this.activeDealsCount > this.activeDealsCountMax ||
          this.activeDealsCountMax === 0
        ) {
          this.activeDealsCountMax = this.activeDealsCount;
        }

        this.allDealsCount = response.data.statistics_leadhistory.reduce(
          (count, doc) => count + doc.leads.length,
          0
        );
        if (
          this.allDealsCount > this.allDealsCountMax ||
          this.allDealsCountMax === 0
        ) {
          this.allDealsCountMax = this.allDealsCount;
        }

        // Initialize the object to hold status counts
        const statusCounts = {};

        // Process the response data to count statuses
        response.data.statistics_leadhistory.forEach((doc) => {
          doc.leads.forEach((lead) => {
            if (statusCounts[lead.status]) {
              statusCounts[lead.status]++;
            } else {
              statusCounts[lead.status] = 1;
            }
          });
        });

        // Prepare the labels and values arrays
        this.leadsStatusChart = {
          labels: Object.keys(statusCounts),
          values: Object.values(statusCounts),
        };

        // Initialize the object to hold type counts
        const typeCounts = {};

        // Process the response data to count lead types
        response.data.statistics_leadhistory.forEach((doc) => {
          doc.leads.forEach((lead) => {
            if (typeCounts[lead.lead_type]) {
              typeCounts[lead.lead_type]++;
            } else {
              typeCounts[lead.lead_type] = 1;
            }
          });
        });

        // Prepare the labels and values arrays
        this.leadsTypeChart = {
          labels: Object.keys(typeCounts),
          values: Object.values(typeCounts),
        };
      } catch (err) {
        this.error = err.message;
      } finally {
        this.loading.history = false;
      }
    },

    async fetchLeadsHistory() {
      try {
        this.leadsHistory = [];
        this.loading.history = true;
        const response = await apiClient.post(
          `${baseUrl}/bids/statistic/history`,
          {
            startDate: this.dateRanges.history.startDate,
            endDate: this.dateRanges.history.endDate,
          }
        );
        this.leadsHistory = response.data.statistics_leadhistory;
      } catch (err) {
        this.error = err.message;
      } finally {
        this.loading.history = false;
      }
    },

    async fetchCommissionStats() {
      try {
        this.loading.commission = true;
        this.commissionStats = null;
        const response = await apiClient.post(
          `${baseUrl}/bids/statistic/commission`,
          {
            startDate: this.dateRanges.commission.startDate,
            endDate: this.dateRanges.commission.endDate,
          }
        );
        this.commissionStats = response.data.statistics_commision[0];
      } catch (err) {
        this.error = err.message;
      } finally {
        this.loading.commission = false;
      }
    },

    async fetchStatsByLeads() {
      try {
        this.loading.leadsStatusChart = true;
        this.loading.leadsTypeChart = true;
        this.loading.statsByLeads = true;
        this.leadsStatusChart = {
          labels: [],
          values: [],
        };
        const response = await apiClient.post(
          `${baseUrl}/bids/statistic/history`,
          {
            startDate: this.dateRanges.statsByLeads.startDate,
            endDate: this.dateRanges.statsByLeads.endDate,
          }
        );

        // Initialize the object to hold status counts
        const statusCounts = {};

        // Process the response data to count statuses
        response.data.statistics_leadhistory.forEach((doc) => {
          doc.leads.forEach((lead) => {
            if (statusCounts[lead.status]) {
              statusCounts[lead.status]++;
            } else {
              statusCounts[lead.status] = 1;
            }
          });
        });

        // Prepare the labels and values arrays
        this.leadsStatusChart = {
          labels: Object.keys(statusCounts),
          values: Object.values(statusCounts),
        };

        const typeCounts = {};

        // Process the response data to count lead types
        response.data.statistics_leadhistory.forEach((doc) => {
          doc.leads.forEach((lead) => {
            if (typeCounts[lead.lead_type]) {
              typeCounts[lead.lead_type]++;
            } else {
              typeCounts[lead.lead_type] = 1;
            }
          });
        });

        // Prepare the labels and values arrays
        this.leadsTypeChart = {
          labels: Object.keys(typeCounts),
          values: Object.values(typeCounts),
        };
      } catch (err) {
        this.error = err.message;
      } finally {
        this.loading.statsByLeads = false;
        this.loading.leadsStatusChart = false;
        this.loading.leadsTypeChart = false;
      }
    },

    updateDateRange(type, startDate, endDate) {
      this.dateRanges[type] = {
        startDate,
        endDate,
      };
    },
  },

  getters: {
    isLoading: (state) => (type) => state.loading[type],
    hasError: (state) => state.error !== null,
    getActiveDealsCount: (state) => state.activeDealsCount,
    getAllDealsCount: (state) => state.allDealsCount,
    getNewLeadsStats: (state) => state.newLeadsStats,
    getLeadsHistory: (state) => state.leadsHistory,
    getCommissionStats: (state) => state.commissionStats,
    getDateRange: (state) => (type) => state.dateRanges[type],
    getLeadsStatusChart: (state) => state.leadsStatusChart,
    getLeadsTypeChart: (state) => state.leadsTypeChart,
    getAllDealsCountMax: (state) => state.allDealsCountMax,
    getActiveDealsCountMax: (state) => state.activeDealsCountMax,
  },
});
