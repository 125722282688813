<template>
  <div>
    <a-table
      class="stripped table-desktop"
      :columns="columns"
      :data-source="paginatedLeadsHistory"
      :pagination="paginationConfig"
      :row-key="(record) => record.doc_number"
      :customRow="
        (record) => {
          return {
            onClick: () => {
              handleRowClick(record);
            },
          };
        }
      "
      row-class-name="clickable-row"
    >
      <template #bodyCell="{ column, record }">
        <template v-if="column.key === 'lead_id'">
          <div>
            <div class="fs-12 fw-normal text-black">
              {{ record.lead_id.slice(0, 4) }}
            </div>
          </div>
        </template>
        <template v-else-if="column.key === 'created_at'">
          <div class="fs-12 fw-normal text-black">
            {{ formatDate(record.created_at) }}
          </div>
        </template>
        <template v-else-if="column.key === 'lead_type'">
          <td>
            <span :class="'badge badge-type badge-' + record.lead_type">{{
              record.lead_type
            }}</span>
          </td>
        </template>
        <template v-else-if="column.key === 'inn'">
          <div class="fs-12 fw-normal text-black">{{ record.inn }}</div>
        </template>
        <template v-else-if="column.key === 'status'">
          <td class="d-flex w-100">
            <span
              :class="'badge badge-status badge-' + record.status.toLowerCase()"
            >
              {{
                record.status === "new"
                  ? "Новая"
                  : record.status === "rejected"
                  ? "Отклонена"
                  : record.status === "accepted"
                  ? "Принята"
                  : record.status === "completed"
                  ? "Завершена"
                  : record.status === "closed"
                  ? "Закрыта"
                  : record.status === "finished"
                  ? "Завершена"
                  : record.status === "reserved"
                  ? "Забронирована"
                  : record.status
              }}</span
            >
          </td>
        </template>
      </template>
    </a-table>

    <div class="table-mobile-wrapper">
      <table class="table dataTable table-mobile">
        <thead class="thead-light">
          <tr>
            <th>ID</th>
            <th>Дата создания</th>
            <th>ИНН</th>
            <th>Статус</th>
          </tr>
        </thead>
        <tbody>
          <template
            v-for="item in paginatedLeadsHistory"
            :key="item.doc_number"
          >
            <tr class="border-none">
              <td>{{ item.lead_id.slice(0, 4) }}</td>
              <td>{{ formatDate(item.created_at) }}</td>
              <td>{{ item.inn }}</td>
              <td>
                <span
                  :class="
                    'badge badge-status badge-' + item.status.toLowerCase()
                  "
                >
                  {{
                    item.status === "new"
                      ? "Новая"
                      : item.status === "rejected"
                      ? "Отклонена"
                      : item.status === "accepted"
                      ? "Принята"
                      : item.status === "completed"
                      ? "Завершена"
                      : item.status === "closed"
                      ? "Закрыта"
                      : item.status === "finished"
                      ? "Завершена"
                      : item.status === "reserved"
                      ? "Забронирована"
                      : item.status
                  }}</span
                >
              </td>
              <td>
                <div class="d-flex gap-2">
                  <button
                    v-for="control in item.Controls"
                    :key="control.title"
                    class="btn-w-s fs-12 w-auto"
                    :class="control.class"
                    @click="control.event"
                  >
                    {{ control.title }}
                  </button>
                </div>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { useStatistics } from "@/composables/useStatistics";
import moment from "moment";
import { useRouter } from "vue-router";
const { leadsHistory } = useStatistics();
const router = useRouter();

const columns = [
  { title: "ID", dataIndex: "lead_id", key: "lead_id" },
  { title: "Дата создания", dataIndex: "created_at", key: "created_at" },
  { title: "Тип сделки", dataIndex: "lead_type", key: "lead_type" },
  { title: "ИНН", dataIndex: "inn", key: "inn" },
  { title: "Статус", dataIndex: "status", key: "status" },
];

const paginatedLeadsHistory = computed(() => {
  const flatLeads = leadsHistory.value.flatMap((doc) => {
    const lastLead = doc.leads[doc.leads.length - 1];
    return lastLead ? [lastLead] : [];
  });
  return flatLeads;
});

const paginationConfig = {
  defaultPageSize: 5,
  showSizeChanger: true,
  pageSizeOptions: ["5", "10", "20", "30"],
  showTotal: (total, range) => `Показано ${range[0]}-${range[1]} из ${total}`,
  locale: {
    items_per_page: "элементов на странице",
  },
};

const formatDate = (date) => {
  return moment(date).locale("ru").format("DD MMM YYYY");
};

const handleRowClick = (record) => {
  router.push(
    record.status === "accepted" ||
      record.status === "reserved" ||
      record.status === "finished"
      ? `/deals/${record.lead_id}`
      : `#`
  );
};
</script>

<style lang="scss" scoped>
.table-desktop {
  display: block;
  @media (max-width: 768px) {
    display: none;
  }
}
.table-mobile-wrapper {
  display: none;
  @media (max-width: 768px) {
    display: block;
  }
}

.clickable-row {
  cursor: pointer;
}
</style>
