import { defineStore } from "pinia";
import apiClient from "@/axios";
import { baseUrl } from "@/config";
import { useAuthStore } from "@/stores/auth";

export const useRequestsStore = defineStore("requests", {
  state: () => ({
    newBids: [],
    requests: [],
    acceptedDealsCount: 0,
    finishedDealsCount: 0,
    reservedDealsCount: 0,
  }),
  getters: {
    getRequests: (state) => state.requests,
    getNewBids: (state) => state.newBids,
    getAcceptedDealsCount: (state) => state.acceptedDealsCount,
    getFinishedDealsCount: (state) => state.finishedDealsCount,
    getReservedDealsCount: (state) => state.reservedDealsCount,
  },
  actions: {
    addRequests(requests) {
      this.requests.push(requests);
    },
    async fetchRequests() {
      const authStore = useAuthStore();
      const deals = await apiClient.get(`${baseUrl}/bids/`);
      this.requests = deals.data
        .filter((item) => item.manager_id === authStore.getCurrentManagerID)
        .filter((item) => item.status !== "new");
      this.newBids = deals.data.filter(
        (item) =>
          // new bids for all managers
          (item.status === "new" &&
            item.manager_id === "00000000-0000-0000-0000-000000000000") ||
          // new bids for current manager
          (item.status === "new" &&
            item.manager_id === authStore.getCurrentManagerID)
      );
      this.acceptedDealsCount = deals.data.filter(
        (item) =>
          item.status === "accepted" &&
          item.manager_id === authStore.getCurrentManagerID
      ).length;
      this.finishedDealsCount = deals.data.filter(
        (item) =>
          item.status === "finished" &&
          item.manager_id === authStore.getCurrentManagerID
      ).length;
      this.reservedDealsCount = deals.data.filter(
        (item) =>
          item.status === "reserved" &&
          item.manager_id === authStore.getCurrentManagerID
      ).length;
    },
    async rejectRequest(data) {
      const response = await apiClient.post(`${baseUrl}/bids/reject`, {
        ...data,
      });
      return response;
    },
    async acceptRequest(data) {
      const response = await apiClient.post(`${baseUrl}/bids/accept`, {
        ...data,
      });

      return response;
    },
    async finishRequest(data) {
      const response = await apiClient.post(
        `${baseUrl}/deals/${data.leadId}/status/finished`,
        {
          ...data,
        }
      );
      return response;
    },
    clearRequestsStore() {
      this.requests = [];
      this.newBids = [];
      this.acceptedDealsCount = 0;
      this.finishedDealsCount = 0;
    },
  },
  persist: {
    storage: sessionStorage,
  },
});
