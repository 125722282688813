<template>
  <layouts-header></layouts-header>
  <layouts-sidebar></layouts-sidebar>

  <!-- Page Wrapper -->
  <div class="page-wrapper">
    <div class="content">
      <div class="row">
        <div class="col-md-12">
          <!-- Page Header -->
          <page-header :title="title" :count="count" :breadcrumb="breadcrumb" />
          <!-- /Page Header -->

          <div class="card">
            <div class="card-header">
              <!-- Filter -->
              <div
                class="d-flex align-items-center justify-content-between flex-wrap row-gap-2"
              >
                <div
                  class="d-flex align-items-center flex-wrap row-gap-2 filter-wrapper"
                >
                  <nav class="deals-nav">
                    <ul class="d-flex align-items-center gap-2">
                      <li v-for="filter in filtersData" :key="filter.value">
                        <button
                          class="btn gap-2"
                          :style="{
                            border: '0',
                          }"
                          @click="handleFilter(filter.value)"
                          :class="
                            filter.value === activeFilter
                              ? 'btn-primary'
                              : 'btn-primary-transparent'
                          "
                        >
                          <p class="mb-0">{{ filter.title }}</p>
                          <span class="badge border bg-primary-900 border-0">{{
                            filter.count
                          }}</span>
                        </button>
                      </li>
                    </ul>
                  </nav>
                </div>

                <div
                  class="d-flex align-items-center flex-wrap row-gap-2"
                  :style="{
                    marginLeft: 'auto',
                  }"
                >
                  <div class="form-sorts dropdown me-2">
                    <a
                      href="javascript:void(0);"
                      data-bs-toggle="dropdown"
                      data-bs-auto-close="outside"
                      class="d-flex align-items-center gap-2"
                      ><svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                        fill="none"
                      >
                        <g clip-path="url(#clip0_2086_3304)">
                          <path
                            d="M2 3.5C2.82843 3.5 3.5 2.82843 3.5 2C3.5 1.17157 2.82843 0.5 2 0.5C1.17157 0.5 0.5 1.17157 0.5 2C0.5 2.82843 1.17157 3.5 2 3.5Z"
                            stroke="#000001"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M3.5 2H13.5"
                            stroke="#000001"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M7 8.5C7.82843 8.5 8.5 7.82843 8.5 7C8.5 6.17157 7.82843 5.5 7 5.5C6.17157 5.5 5.5 6.17157 5.5 7C5.5 7.82843 6.17157 8.5 7 8.5Z"
                            stroke="#000001"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M0.5 7H5.5"
                            stroke="#000001"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M8.5 7H13.5"
                            stroke="#000001"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M12 13.5C12.8284 13.5 13.5 12.8284 13.5 12C13.5 11.1716 12.8284 10.5 12 10.5C11.1716 10.5 10.5 11.1716 10.5 12C10.5 12.8284 11.1716 13.5 12 13.5Z"
                            stroke="#000001"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M10.5 12H0.5"
                            stroke="#000001"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_2086_3304">
                            <rect width="14" height="14" fill="white" />
                          </clipPath>
                        </defs></svg
                      >Фильтр</a
                    >
                    <div
                      class="filter-dropdown-menu dropdown-menu dropdown-menu-md-end p-0"
                    >
                      <div class="filter-set-view">
                        <div class="filter-set-head p-3">
                          <h4><i class="ti ti-filter-share"></i>Фильтр</h4>
                        </div>
                        <div class="accordion" id="accordionExample">
                          <div class="filter-set-content p-3">
                            <div class="filter-set-content-head">
                              <a
                                href="#"
                                data-bs-toggle="collapse"
                                data-bs-target="#status"
                                aria-expanded="true"
                                aria-controls="status"
                                >По статусу</a
                              >
                            </div>
                            <div
                              class="filter-set-contents accordion-collapse collapse show"
                              id="status"
                              data-bs-parent="#accordionExample"
                            >
                              <div class="filter-content-list">
                                <ul>
                                  <li>
                                    <div class="filter-checks">
                                      <label class="checkboxs">
                                        <input
                                          type="radio"
                                          class="status-radio"
                                          id="new"
                                          name="status"
                                          @change="handleFilter('all')"
                                        />
                                        <span class="checkmarks"></span>
                                        Все
                                      </label>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="filter-checks">
                                      <label class="checkboxs">
                                        <input
                                          type="radio"
                                          class="status-radio"
                                          id="new"
                                          name="status"
                                          @change="handleFilter('new')"
                                        />
                                        <span class="checkmarks"></span>
                                        Новые
                                      </label>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="filter-checks">
                                      <label class="checkboxs">
                                        <input
                                          type="radio"
                                          class="status-radio"
                                          id="accepted"
                                          name="status"
                                          @change="handleFilter('accepted')"
                                        />
                                        <span class="checkmarks"></span>
                                        Приняты
                                      </label>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="filter-checks">
                                      <label class="checkboxs">
                                        <input
                                          type="radio"
                                          class="status-radio"
                                          id="rejected"
                                          name="status"
                                          @change="handleFilter('rejected')"
                                        />
                                        <span class="checkmarks"></span>
                                        Отклонены
                                      </label>
                                    </div>
                                  </li>

                                  <li>
                                    <div class="filter-checks">
                                      <label class="checkboxs">
                                        <input
                                          type="radio"
                                          class="status-radio"
                                          id="finished"
                                          name="status"
                                          @change="handleFilter('finished')"
                                        />
                                        <span class="checkmarks"></span>
                                        Завершены
                                      </label>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div class="filter-set-content p-3">
                            <div class="filter-set-content-head">
                              <a
                                href="#"
                                class="collapsed"
                                data-bs-toggle="collapse"
                                data-bs-target="#dealType"
                                aria-expanded="false"
                                aria-controls="dealType"
                                >По типу сделки</a
                              >
                            </div>
                            <div
                              class="filter-set-contents accordion-collapse collapse"
                              id="dealType"
                              data-bs-parent="#accordionExample"
                            >
                              <div class="filter-content-list">
                                <ul>
                                  <li>
                                    <div class="filter-checks">
                                      <label class="checkboxs">
                                        <input type="checkbox" />
                                        <span class="checkmarks"></span>
                                        Лизинг
                                      </label>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /Filter -->
            </div>
            <div class="card-body">
              <!-- Contact List -->
              <div
                v-if="activeFilter === 'new' || activeFilter === 'all'"
                class="table-responsive custom-table mb-0"
              >
                <deals-table-new
                  @update:chooseManager="openChooseManagerModal"
                ></deals-table-new>
              </div>
              <div v-else class="table-responsive custom-table mb-0">
                <deals-table-all
                  :key="activeFilter"
                  :filter="activeFilter"
                ></deals-table-all>
              </div>
              <div class="row align-items-center">
                <div class="col-md-6">
                  <div class="datatable-length"></div>
                </div>
                <div class="col-md-6">
                  <div class="datatable-paginate"></div>
                </div>
              </div>
              <!-- /Contact List -->
            </div>
          </div>

          <div v-if="activeFilter === 'all'" class="card">
            <div class="card-body">
              <!-- Deals all List -->
              <div class="table-responsive custom-table mb-0">
                <deals-table-all
                  :key="activeFilter"
                  :filter="'all'"
                ></deals-table-all>
              </div>
            </div>
          </div>
        </div>
      </div>
      <main-footer></main-footer>
    </div>
    <button
      class="btn-w-s fs-12 w-auto"
      :data-bs-toggle="'modal'"
      :data-bs-target="'#choose_manager_campaign'"
      style="display: none"
      ref="chooseManagerBtnEl"
    >
      Выбрать менеджера
    </button>
    <teleport to="#app">
      <div class="modal fade" id="choose_manager_campaign" role="dialog">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-body">
              <div class="text-center">
                <button
                  type="button"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  ref="chooseManagerModalCloseEl"
                  class="modal-btn-close"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="25"
                    viewBox="0 0 25 25"
                    fill="none"
                  >
                    <path
                      d="M19.7913 5.2085L5.20801 19.7918"
                      stroke="#001615"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M19.7913 19.7918L5.20801 5.2085"
                      stroke="#001615"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </button>
                <h4 class="mb-2">Укажите менеджера</h4>
                <p class="mb-4">
                  Пожалуйста, назначьте менеджера, который будет сопровождать
                  сделки.
                </p>

                <div class="dropdown dropdown-gray mb-2 w-100">
                  <a
                    href="#"
                    ref="chooseManagerDropdownEl"
                    class="text-dark d-inline-flex align-items-center justify-content-between gap-2 w-100"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <template v-if="choosedManager">
                      {{ choosedManager.name }}
                    </template>
                    <template v-else> Выберите из списка </template>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="13"
                      height="7"
                      viewBox="0 0 13 7"
                      fill="none"
                    >
                      <path
                        d="M1.36426 1L6.86426 6L12.3643 1"
                        stroke="#000000"
                        stroke-linecap="ceil"
                        stroke-linejoin="ceil"
                      />
                    </svg>
                  </a>
                  <div class="dropdown-menu">
                    <a
                      v-for="manager in authStore.getUser?.managers"
                      :key="manager.manager_id"
                      class="dropdown-item"
                      href="javascript:void(0);"
                      @click.stop="handleChooseManager(manager)"
                      ><span>{{ manager.name }}</span></a
                    >
                  </div>
                </div>
                <div
                  class="d-flex align-items-center justify-content-center mt-4"
                >
                  <a
                    href="#"
                    class="btn btn-primary w-100"
                    @click.prevent="confirmChooseManager"
                    >Выбрать</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </teleport>
  </div>
  <!-- /Page Wrapper -->
</template>

<script setup>
import "daterangepicker/daterangepicker.css";
import "daterangepicker/daterangepicker.js";
import { ref, computed } from "vue";
import { onMounted } from "vue";
import { useRequestsStore } from "@/stores/requests";
import { useAuthStore } from "@/stores/auth";
import { useToast } from "vue-toast-notification";

const toast = useToast();
const requestsStore = useRequestsStore();
const authStore = useAuthStore();
const count = computed(() => requestsStore.getRequests.length);

const title = "Мои заявки";

const breadcrumb = [
  {
    title: "Заявки",
    link: "/requests",
  },
];
const choosedManager = ref(null);
const chooseManagerBtnEl = ref(null);
const chooseManagerDropdownEl = ref(null);
const chooseManagerModalCloseEl = ref(null);
const handleChooseManager = (manager) => {
  choosedManager.value = manager;
  chooseManagerDropdownEl.value.click();
};

const confirmChooseManager = async () => {
  if (!choosedManager.value) {
    toast.error("Пожалуйста, выберите менеджера");
    return;
  } else {
    authStore.setCurrentManagerID(choosedManager.value.manager_id);
    chooseManagerModalCloseEl.value.click();
    toast.success("Менеджер успешно выбран");
    await requestsStore.fetchRequests();
  }
};

const openChooseManagerModal = () => {
  chooseManagerBtnEl.value.click();
};

const filtersData = computed(() => [
  {
    title: "Все заявки",
    value: "all",
    count: requestsStore.getRequests.length,
  },
  {
    title: "Новые",
    value: "new",
    count: requestsStore.getNewBids.filter(
      (request) => request.status === "new"
    ).length,
  },
  {
    title: "Приняты",
    value: "accepted",
    count: requestsStore.getRequests.filter(
      (request) => request.status === "accepted"
    ).length,
  },
  {
    title: "Забронированы",
    value: "reserved",
    count: requestsStore.getRequests.filter(
      (request) => request.status === "reserved"
    ).length,
  },
  {
    title: "Отклонены",
    value: "rejected",
    count: requestsStore.getRequests.filter(
      (request) => request.status === "rejected"
    ).length,
  },
  {
    title: "Завершены",
    value: "finished",
    count: requestsStore.getRequests.filter(
      (request) => request.status === "finished"
    ).length,
  },
]);

const activeFilter = ref("all");

/**
 * Handles filter changes by setting the active filter.
 * Ensures that the filter exists before setting it.
 * @param {string} value - The value of the selected filter.
 */
const handleFilter = (value) => {
  const selectedFilter = filtersData.value.find(
    (filter) => filter.value === value
  );
  if (selectedFilter) {
    activeFilter.value = selectedFilter.value;
  } else {
    console.warn(
      `Filter with value "${value}" not found. Defaulting to "all".`
    );
    activeFilter.value = "all";
  }
};

onMounted(async () => {
  if (!authStore.getCurrentManagerID) {
    chooseManagerBtnEl.value.click();
  } else {
    await requestsStore.fetchRequests();
  }
});
</script>

<style lang="scss" scoped>
.filter-wrapper {
  @media screen and (max-width: 992px) {
    overflow-x: auto;
    padding-bottom: 8px;
  }
}
</style>
