import { useStatisticsStore } from "@/store/statistics";
import { storeToRefs } from "pinia";

export function useStatistics() {
  const store = useStatisticsStore();
  const {
    getActiveDealsCount,
    getAllDealsCount,
    getNewLeadsStats,
    getLeadsHistory,
    getCommissionStats,
    getDateRange,
    getLeadsStatusChart,
    getLeadsTypeChart,
    getAllDealsCountMax,
    getActiveDealsCountMax,
  } = storeToRefs(store);

  const updateDateRange = async (type, start, end) => {
    store.updateDateRange(type, start, end);
    switch (type) {
      case "activeDeals":
        await store.fetchActiveDealsCount();
        break;
      case "allDeals":
        await store.fetchAllDealsCount();
        break;
      case "newLeads":
        await store.fetchNewLeadsStats();
        break;
      case "history":
        await store.fetchLeadsHistory();
        break;
      case "commission":
        await store.fetchCommissionStats();
        break;
      case "statsByLeads":
        await store.fetchStatsByLeads();
        break;
    }
  };

  const fetchAllStats = async () => {
    await Promise.all([
      store.initialFetchLeadsHistory(),
      store.fetchNewLeadsStats(),
      store.fetchCommissionStats(),
    ]);
  };

  const isLoading = (type) => store.isLoading(type);
  const hasError = store.hasError;

  return {
    // Data
    activeDealsCount: getActiveDealsCount,
    allDealsCount: getAllDealsCount,
    newLeadsStats: getNewLeadsStats,
    leadsHistory: getLeadsHistory,
    commissionStats: getCommissionStats,
    leadsStatusChart: getLeadsStatusChart,
    leadsTypeChart: getLeadsTypeChart,
    allDealsCountMax: getAllDealsCountMax,
    activeDealsCountMax: getActiveDealsCountMax,
    // Date ranges
    getDateRange,
    updateDateRange,

    // Loading states
    isLoading,
    hasError,

    // Methods
    fetchAllStats,
  };
}
