<template>
  <div class="page-header">
    <div class="row align-items-center">
      <div class="col-6">
        <h4 class="page-title mb-0">
          {{ title }}
          <button
            v-if="copyBtn"
            class="btn btn-copy btn-sm"
            :style="{ marginLeft: '8px' }"
            @click="handleCopyContent"
          >
            <i class="ti ti-copy"></i>
          </button>
          <span v-if="count" class="count-title">{{ count }}</span>
        </h4>
      </div>
      <!-- Breadcrumb -->
      <div class="col-6">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb mb-0">
            <li class="breadcrumb-item">
              <router-link to="/requests"
                ><i class="ti ti-home"></i
              ></router-link>
            </li>
            <li
              class="breadcrumb-item"
              v-for="(item, index) in breadcrumb"
              :key="index"
            >
              <template v-if="index === breadcrumb.length - 1">
                <span aria-current="page">{{ item.title }}</span>
              </template>
              <template v-else>
                <router-link :to="item.link">{{ item.title }}</router-link>
              </template>
            </li>
          </ol>
        </nav>
      </div>
      <!-- /Breadcrumb -->
    </div>
  </div>
</template>

<script setup>
import { useToast } from "vue-toast-notification";
const toast = useToast();
const props = defineProps({
  title: String,
  count: Number,
  breadcrumb: {
    type: Array,
    default: () => [],
  },
  copyBtn: Boolean,
  copyContent: String,
});

const handleCopyContent = async () => {
  if (navigator.clipboard && navigator.clipboard.writeText) {
    try {
      await navigator.clipboard.writeText(props.copyContent);
      toast.success("ID сделки скопирован в буфер обмена");
    } catch (error) {
      console.error("Clipboard write failed:", error);
      toast.error("Не удалось скопировать ID сделки");
    }
  } else {
    // Fallback for older browsers
    const textarea = document.createElement("textarea");
    textarea.value = props.copyContent;
    // Prevent scrolling to bottom
    textarea.style.position = "fixed";
    textarea.style.top = "0";
    textarea.style.left = "0";
    textarea.style.opacity = "0";

    document.body.appendChild(textarea);
    textarea.focus();
    textarea.select();

    try {
      const successful = document.execCommand("copy");
      if (successful) {
        toast.success("ID сделки скопирован в буфер обмена");
      } else {
        throw new Error("ExecCommand failed");
      }
    } catch (err) {
      console.error("Fallback copy failed:", err);
      toast.error("Не удалось скопировать ID сделки");
    }

    document.body.removeChild(textarea);
  }
};
</script>
