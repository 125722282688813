<template>
  <!-- Sidebar -->
  <div class="sidebar" id="sidebar">
    <perfect-scrollbar
      class="scroll-area"
      :settings="settings"
      @ps-scroll-y="scrollHanle"
    >
      <div class="sidebar-inner slimscroll">
        <div id="sidebar-menu" class="sidebar-menu">
          <main-sidebar v-if="!isAdmin"></main-sidebar>
          <admin-sidebar v-if="isAdmin"></admin-sidebar>
          <div class="sidebar-footer">
            <a href="#" class="btn" @click.prevent="handleLogout">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="22"
                viewBox="0 0 22 22"
                fill="none"
              >
                <path
                  opacity="0.4"
                  d="M0.764648 5.59325C0.764648 3.01643 2.89911 0.917969 5.52145 0.917969H10.7372C13.3542 0.917969 15.4833 3.01012 15.4833 5.58274V17.2694C15.4833 19.8473 13.3489 21.9447 10.7255 21.9447H5.51182C2.89376 21.9447 0.764648 19.8525 0.764648 17.2799V16.2916V5.59325Z"
                  fill="#005534"
                />
                <path
                  d="M21.5588 10.8581L18.567 7.79976C18.2577 7.48436 17.7601 7.48436 17.4519 7.80187C17.1447 8.11937 17.1458 8.63137 17.454 8.94677L19.0933 10.6215H17.5215H8.70052C8.26536 10.6215 7.91211 10.9843 7.91211 11.4311C7.91211 11.8789 8.26536 12.2406 8.70052 12.2406H19.0933L17.454 13.9154C17.1458 14.2308 17.1447 14.7428 17.4519 15.0603C17.6065 15.219 17.8082 15.2989 18.011 15.2989C18.2117 15.2989 18.4134 15.219 18.567 15.0624L21.5588 12.0051C21.7073 11.8527 21.7913 11.6466 21.7913 11.4311C21.7913 11.2166 21.7073 11.0105 21.5588 10.8581Z"
                  fill="#005534"
                /></svg
              >Выйти
            </a>
            <p>
              По вопросам и предложениям обращайтесь на почту
              <a href="mailto:lkpsupport@gcap.ru">lkpsupport@gcap.ru</a>
            </p>
          </div>
        </div>
      </div>
    </perfect-scrollbar>
  </div>
  <!-- /Sidebar -->
</template>

<script setup>
import { PerfectScrollbar } from "vue3-perfect-scrollbar";
import "vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css";
import { useAuthStore } from "@/stores/auth";
import { useRouter } from "vue-router";
const authStore = useAuthStore();
const router = useRouter();

const handleLogout = async () => {
  await authStore.logout();
  router.push("/");
};

const props = defineProps({
  isAdmin: {
    type: Boolean,
    default: false,
  },
});

const settings = {
  suppressScrollX: true,
};

const scrollHanle = () => {};
</script>
