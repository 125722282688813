<template>
  <div class="account-content login-page">
    <div class="login-wrapper">
      <two-factor-verification type="sms" />
    </div>
    <div class="login-footer">
      <main-footer />
    </div>
  </div>
</template>
