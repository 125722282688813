<template>
  <div class="account-content login-page">
    <div class="login-wrapper">
      <account-blocked />
    </div>
    <div class="login-footer">
      <main-footer />
    </div>
  </div>
</template>
