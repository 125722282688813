<template>
  <div class="chat chat-messages" id="middle">
    <div class="slimscroll">
      <perfect-scrollbar
        ref="ps"
        class="scroll-area-two"
        :settings="{
          suppressScrollX: true,
        }"
        @ps-scroll-y="scrollHandle"
        @mouseenter="addScrollBlock"
        @mouseleave="removeScrollBlock"
      >
        <div class="chat-header">
          <div class="user-details">
            <div class="d-lg-none">
              <ul class="list-inline mt-2 me-2">
                <li class="list-inline-item">
                  <a
                    class="text-muted px-0 left_sides"
                    href="javascript:void(0);"
                    data-chat="open"
                    @click="emitOpenSidebar"
                  >
                    <i class="fas fa-arrow-left"></i>
                  </a>
                </li>
              </ul>
            </div>
            <figure class="avatar ms-1">
              <img :src="chatData.avatar" alt="avatar" class="rounded-circle" />
            </figure>
            <div class="mt-1">
              <h5>{{ chatData.name }}</h5>
            </div>
          </div>
          <div class="chat-options">
            <ul class="list-inline">
              <li class="list-inline-item">
                <a
                  href="javascript:void(0)"
                  @click="showChat"
                  class="btn btn-outline-light chat-search-btn"
                  data-bs-toggle="tooltip"
                  data-bs-placement="bottom"
                  title="Search"
                >
                  <i class="bx bx-search"></i>
                </a>
              </li>
              <li class="list-inline-item">
                <a
                  href="javascript:void(0)"
                  class="btn btn-outline-light chat-sound"
                  @click="soundToggle"
                >
                  <i
                    v-if="authStore?.getIsSoundActive"
                    class="bx bx-volume-full"
                  ></i>
                  <i v-else class="bx bx-volume-mute"></i>
                </a>
              </li>
            </ul>
          </div>
          <!-- Chat Search -->
          <div class="chat-search" :class="{ 'visible-chat': chatVisible }">
            <form @submit.prevent="handleSearchSubmit">
              <span class="form-control-feedback">
                <i class="bx bx-search"></i>
              </span>
              <input
                type="text"
                name="chat-search"
                v-model="searchTerm"
                placeholder="Поиск по сообщениям"
                class="form-control"
              />
              <div
                class="close-btn-chat btn btn-outline-light no-bg"
                :style="{ border: 'none' }"
                @click="hideChat"
              >
                <i class="bx bx-x"></i>
              </div>
            </form>
          </div>
          <!-- /Chat Search -->
        </div>
        <div class="chat-body">
          <div class="messages" ref="messagesContainer">
            <template
              v-for="(group, groupIndex) in filteredMessages"
              :key="groupIndex"
            >
              <div class="chat-line">
                <span class="chat-date">{{ group.dateLabel }}</span>
              </div>
              <div
                class="chats"
                v-for="(message, msgIndex) in group.messages"
                :key="msgIndex"
                :class="{ 'chats-right': message.isOwn }"
              >
                <div v-if="!message.isOwn" class="chat-avatar">
                  <img
                    :src="chatData.avatar"
                    class="rounded-circle dreams_chat"
                    alt="avatar"
                  />
                </div>
                <div
                  class="chat-content"
                  :class="{
                    'message-highlight':
                      searchTerm &&
                      message.content
                        .toLowerCase()
                        .includes(searchTerm.toLowerCase()),
                  }"
                >
                  <div
                    class="chat-profile-name"
                    :class="{ 'text-end': message.isOwn }"
                  >
                    <h6>
                      {{
                        message.sender === "admin"
                          ? "GCAP"
                          : authStore.getUser?.name
                      }}
                      <span>{{ formatMessageTime(message.time) }}</span>
                    </h6>
                  </div>
                  <div
                    class="message-content"
                    :data-id="message.id"
                    :data-status="message.status"
                    :data-is-own="message.isOwn"
                  >
                    {{ message.content }}
                    <div v-if="message.files?.length > 0" class="message-files">
                      <div
                        v-for="file in message.files"
                        :key="file.id"
                        class="message-file"
                      >
                        <div v-if="file.file_type.includes('image')">
                          <img
                            class="file-image"
                            :src="
                              isDev
                                ? 'https://rsnake-dev.vladdizlaw.com/' +
                                  file.path
                                : getFilePath(file.path)
                            "
                            :alt="file.original_file_name"
                          />
                        </div>
                        <div v-else>{{ file.original_file_name }}</div>

                        <a
                          class="message-file-over"
                          :href="
                            isDev
                              ? 'https://rsnake-dev.vladdizlaw.com/' + file.path
                              : getFilePath(file.path)
                          "
                          :target="
                            file.file_type.includes('image') ||
                            file.file_type.includes('pdf')
                              ? '_blank'
                              : ''
                          "
                          download
                        >
                          <i class="ti ti-download"></i>
                        </a>
                      </div>
                    </div>
                    <div
                      style="position: absolute; bottom: -2px; right: 10px"
                      :style="{
                        color:
                          message.status === 'read' || message.status === 'sent'
                            ? '#005534'
                            : message.status === 'sending'
                            ? '#999'
                            : '#fc0027',
                      }"
                    >
                      <template
                        v-if="
                          message.status === 'sending' ||
                          message.status === 'read' ||
                          message.status === 'sent'
                        "
                      >
                        <i
                          v-show="message.status === 'read'"
                          class="ti ti-check"
                          :style="{ marginRight: '-8px' }"
                        ></i>
                        <i class="ti ti-check"></i>
                      </template>
                      <template v-else>
                        <i class="ti ti-x"></i>
                      </template>
                    </div>
                  </div>
                </div>
                <!-- User's avatar -->
                <div v-if="message.isOwn" class="chat-avatar">
                  <img
                    src="@/assets/img/profiles/avatar-default.svg"
                    class="rounded-circle dreams_chat"
                    alt="avatar"
                  />
                </div>
              </div>
            </template>
            <!-- No Messages Placeholder -->
            <div class="chat-line" v-if="filteredMessages.length === 0">
              <span class="chat-date">Сообщений не найдено.</span>
            </div>
          </div>
        </div>
      </perfect-scrollbar>
    </div>
    <div class="chat-footer">
      <form @submit.prevent="sendMessage">
        <div class="d-flex w-100">
          <textarea
            v-model="data.newMessage"
            type="text"
            class="form-control chat_form"
            placeholder="Введите ваше сообщение..."
            @keydown.enter.exact.prevent="sendMessage"
          ></textarea>
        </div>
        <div class="chat-controls">
          <div class="chat-files">
            <div v-if="data.files.length">
              <ul>
                <li
                  v-for="(file, index) in data.files"
                  :key="index"
                  class="chat-file-item"
                >
                  <template v-if="file.name.length > 14">
                    {{
                      file.name.split(".").slice(0, -1).join(".").slice(0, 14)
                    }}.{{ ".." + file.name.split(".").pop() }} ({{
                      formatFileSize(file.size)
                    }})
                  </template>
                  <template v-else>
                    {{ file.name }} ({{ formatFileSize(file.size) }})
                  </template>
                  <button @click="removeFile(index)" class="btn-remove">
                    <i class="ti ti-x"></i>
                  </button>
                </li>
              </ul>
            </div>
          </div>
          <div class="d-flex gap-2">
            <label class="chat-add-file">
              <input
                type="file"
                hidden
                @change="addFile"
                multiple
                accept=".csv, .doc, .docx, .jpeg, .jpg, .png, .webp, .pdf, .xls, .xlsx"
              />
              <svg
                width="19"
                height="23"
                viewBox="0 0 19 23"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16.7419 0.310791H6.79592V6.46484C6.79592 6.80674 6.51619 7.08647 6.1743 7.08647H0.331055V20.7311C0.331055 21.8189 1.20132 22.6892 2.28916 22.6892H16.7419C17.7986 22.6892 18.7 21.8189 18.7 20.7311V2.23782C18.6689 1.18106 17.7986 0.310791 16.7419 0.310791ZM13.0121 13.7067H10.1216V16.6284C10.1216 16.9703 9.84187 17.25 9.49997 17.25C9.15808 17.25 8.87835 16.9703 8.87835 16.6284V13.7067H5.95673C5.61484 13.7067 5.33511 13.427 5.33511 13.0851C5.33511 12.7432 5.61484 12.4635 5.95673 12.4635H8.87835V9.54187C8.87835 9.19998 9.15808 8.92025 9.49997 8.92025C9.84187 8.92025 10.1216 9.19998 10.1216 9.54187V12.4635H13.0121C13.354 12.4635 13.6338 12.7432 13.6338 13.0851C13.6338 13.427 13.3851 13.7067 13.0121 13.7067Z"
                  fill="#005534"
                />
                <path
                  d="M5.52167 5.84322V0.714844C5.45951 0.777006 5.36626 0.839168 5.3041 0.932411L0.859507 5.65674C0.797345 5.7189 0.766264 5.78106 0.704102 5.84322H5.52167Z"
                  fill="#005534"
                />
              </svg>
            </label>

            <button class="btn btn-primary" type="submit">Отправить</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>
<script setup>
import { PerfectScrollbar } from "vue3-perfect-scrollbar";
import "vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css";
import {
  reactive,
  computed,
  watch,
  onMounted,
  nextTick,
  ref,
  onUnmounted,
} from "vue";
import { useAuthStore } from "@/stores/auth";
import { useDealStore } from "@/stores/deal";
import { useMessagesStore } from "@/stores/messages";
import { useRoute } from "vue-router";
const props = defineProps({
  chatData: {
    type: Object,
    required: false,
    default: () => ({}),
  },
  messages: {
    type: Array,
    default: () => [],
  },
});
const route = useRoute();
const emit = defineEmits(["message-sent", "open-sidebar"]);
const authStore = useAuthStore();
const messagesStore = useMessagesStore();
const dealStore = useDealStore();
const token = authStore.getToken;

const ps = ref(null);
const messagesContainer = ref(null);
const data = reactive({
  showSidebar: false,
  chatVisible: false,
  settings: {
    suppressScrollX: true,
  },
  newMessage: "",
  searchTerm: "",
  files: [],
  uploadedFilesIDs: [],
});

const groupedMessages = computed(() => {
  if (!messagesStore.getMessages || messagesStore.getMessages.length === 0)
    return [];
  const groups = [];
  let lastDate = "";
  messagesStore.getMessages.forEach((message) => {
    const messageDate = new Date(message.created_at);
    const dateLabel = getMessageDateLabel(messageDate);
    if (dateLabel !== lastDate) {
      groups.push({
        dateLabel: dateLabel,
        messages: [],
      });
      lastDate = dateLabel;
    }
    groups[groups.length - 1].messages.push({
      id: message.id,
      content: message.value,
      sender: message.author,
      time: message.created_at,
      isOwn: message.author !== "admin",
      status: message.status,
      files: message.files,
    });
  });
  return groups;
});

const addFile = async (event) => {
  const selectedFiles = Array.from(event.target.files);
  data.files.push(...selectedFiles);
  await uploadFile();
};

const uploadFile = async () => {
  const formData = new FormData();

  data.files.forEach((file) => {
    formData.append("files", file);
  });

  const response = await messagesStore.uploadFiles(formData);
  data.uploadedFilesIDs = response.data;
};

const removeFile = async (index) => {
  data.files.splice(index, 1);
  await deleteFile(data.uploadedFilesIDs[index]);
};

const deleteFile = async (id) => {
  const res = await messagesStore.deleteFile(id);
};

const formatFileSize = (size) => {
  if (size < 1024) return `${size} bytes`;

  const units = ["bytes", "KB", "MB", "GB", "TB"];
  let index = 0;

  while (size >= 1024 && index < units.length - 1) {
    size /= 1024;
    index++;
  }

  return `${size.toFixed(2)} ${units[index]}`;
};

const getFilePath = (path) => {
  if (typeof window !== "undefined") {
    return `${window.location.origin}/${path}`;
  }
  return path;
};

const filteredMessages = computed(() => {
  if (!data.searchTerm.trim()) {
    return groupedMessages.value;
  }
  const term = data.searchTerm.toLowerCase();
  return groupedMessages.value
    .map((group) => {
      const filteredMsgs = group.messages.filter((msg) =>
        msg.content.toLowerCase().includes(term)
      );
      if (filteredMsgs.length > 0) {
        return {
          dateLabel: group.dateLabel,
          messages: filteredMsgs,
        };
      }
      return null;
    })
    .filter((group) => group !== null);
});
watch(
  () => messagesStore.getMessages,
  () => {
    nextTick(() => {
      scrollToBottom();
      messagesContainer.value
        .querySelectorAll(".message-content")
        .forEach((el) => {
          if (el.dataset.status !== "read" && el.dataset.isOwn !== "true") {
            observer.value.observe(el);
          }
        });
    });
  }
);

const observer = ref(null);

observer.value = new IntersectionObserver(
  async (entries) => {
    entries.forEach(async (entry) => {
      if (entry.isIntersecting) {
        const ids = [];
        if (entry.target.dataset.id) {
          ids.push(+entry.target.dataset.id);
        }

        try {
          const response = await messagesStore.readMessages(
            route.params.id,
            dealStore.getDeal?.fr_lk?.uuid,
            ids
          );
          if (response.status === 201 || response.status === 200) {
            // to disable the observer
            entry.target.dataset.status = "read";
            // status update in the UI via the store
            messagesStore.readMessage(entry.target.dataset.id);
          }
        } catch (error) {
          console.log(error);
        }
      }
    });
  },
  {
    root: messagesContainer.value,
    threshold: 0,
  }
);
const soundToggle = () => {
  console.log(!authStore?.getIsSoundActive);
  authStore.setIsSoundActive(!authStore?.getIsSoundActive);
};
onMounted(() => {
  scrollToBottom();
});

onUnmounted(() => {
  observer.value.disconnect();
  messagesStore.clearMessages();
});

const emitOpenSidebar = () => {
  emit("open-sidebar");
};
const scrollHandle = () => {
  // Existing scroll handler logic
};
const handleClick = () => {
  // Existing click handler logic
};
const toggleSidebar = () => {
  data.showSidebar = !data.showSidebar;
};

const addScrollBlock = () => {
  window.addEventListener("wheel", preventScroll, { passive: false });
};

const removeScrollBlock = () => {
  window.removeEventListener("wheel", preventScroll);
};

const preventScroll = (event) => {
  const container = messagesContainer.value;
  if (container) {
    event.preventDefault();
  }
};
const showChat = () => {
  data.chatVisible = true;
};
const hideChat = () => {
  data.chatVisible = false;
};

const fetchChats = async (leadId, mangerId) => {
  await messagesStore.fetchChats(leadId, mangerId);
};
fetchChats(route.params.id, authStore.getCurrentManagerID);

const sendMessage = async () => {
  if (data.newMessage.trim() === "" && data.uploadedFilesIDs.length === 0)
    return;
  await messagesStore.sendMessage(
    route.params.id,
    authStore.getCurrentManagerID,
    data.newMessage.trim(),
    data.uploadedFilesIDs
  );
  data.newMessage = "";
  data.files = [];
  data.uploadedFilesIDs = [];

  nextTick(() => {
    scrollToBottom();
  });
};
const getMessageDateLabel = (date) => {
  const today = new Date();
  const yesterday = new Date();
  yesterday.setDate(today.getDate() - 1);
  // Reset the time components for accurate comparison
  today.setHours(0, 0, 0, 0);
  yesterday.setHours(0, 0, 0, 0);
  date.setHours(0, 0, 0, 0);
  if (date.getTime() === today.getTime()) {
    return "Сегодня";
  } else if (date.getTime() === yesterday.getTime()) {
    return "Вчера";
  } else {
    return date.toLocaleDateString("ru-RU", {
      day: "numeric",
      month: "long",
      year: "numeric",
    });
  }
};
const formatMessageTime = (rawTime) => {
  const date = new Date(rawTime);
  return date.toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
  });
};
const scrollToBottom = () => {
  if (ps.value.ps) {
    ps.value.ps.update();
    const container = ps.value.ps.element.querySelector(".ps__rail-y");
    if (container) {
      const scrollHeight = container.parentElement.scrollHeight;
      container.parentElement.scrollTop = scrollHeight;
    }
  }
};
const handleSearchSubmit = () => {
  // After searching, scroll to the first matched message
  nextTick(() => {
    const firstMatch = this.$el.querySelector(".message-highlight");
    if (firstMatch) {
      firstMatch.scrollIntoView({ behavior: "smooth" });
    }
  });
};

const isDev = computed(() => {
  return (
    window?.location.origin === "http://localhost:3000" ||
    "https://autopartner-account.netlify.app"
  );
});
</script>

<style scoped>
.chats {
  display: flex;
  margin-bottom: 15px;
}
.chats .chat-avatar {
  margin-right: 10px;
}
.chats-right {
  flex-direction: row-reverse;
}
.chats-right .chat-avatar {
  margin-left: 10px;
  margin-right: 0;
}
.chat-content {
  max-width: 70%;
  padding: 0px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  -webkit-flex-direction: column;
  align-items: flex-start;
  -webkit-align-items: flex-start;
}
.chats-right .chat-content {
  text-align: right;
}
.chat-profile-name h6 {
  margin: 0;
  font-size: 14px;
}
.chat-profile-name span {
  font-size: 12px;
  color: #888;
  margin-left: 5px;
}
.message-content {
  margin-top: 5px;
}
.chat-line {
  text-align: center;
  margin: 15px 0;
  color: #888;
}
.avatar img {
  width: 40px;
  height: 40px;
}
.chat-avatar img {
  width: 40px;
  height: 40px;
}
.message-highlight {
  background-color: #ffffcc;
}
.messages {
  scroll-behavior: smooth;
}
</style>
