<template>
  <a-table
    class="table thead-light table-hover"
    :columns="columns"
    :data-source="filteredData"
    :customRow="customRowHandler"
  >
    <template #bodyCell="{ column, record }">
      <template v-if="column.key === 'Activity_Type'">
        <div>
          <span :class="record.statusclass">
            <i :class="record.icon"></i>{{ record.status }}
          </span>
        </div>
      </template>
    </template>
  </a-table>
</template>

<script setup>
import { ref, computed, watch } from "vue";
import { useAuthStore } from "@/stores/auth";
import { useRouter } from "vue-router";

const props = defineProps({
  searchQuery: {
    type: String,
    default: "",
  },
});

const router = useRouter();
const authStore = useAuthStore();
const data = computed(() => authStore.getActivities);

const flatData = computed(() => {
  const activities = [];
  data.value.forEach((item) => {
    item.activities.forEach((activity) => {
      activities.push({
        id: activity.id,
        title: activity.description
          .replace("rejected", `"Отклонена"`)
          .replace("accepted", `"Принята"`)
          .replace("reserved", `"Забронирована"`)
          .replace("finished", `"Завершена"`),
        dealID: item.lead_id,
        created_date: formatDate(activity.created_at),
        icon: getIconByType(activity.type),
        status: getStatusByType(activity.type),
        statusclass: getStatusClassByType(activity.type),
        lead_status: activity.lead_status,
      });
    });
  });
  return activities;
});

// Функция для форматирования даты в MM/DD/YYYY
function formatDate(dateString) {
  const date = new Date(dateString);
  const month = date.getMonth() + 1; // Months are zero-based
  const day = date.getDate();
  const year = date.getFullYear();
  const hours = date.getHours();
  const minutes = date.getMinutes();
  return `${month}/${day}/${year}, ${hours}:${minutes}`;
}

// Computed property for filtered data based on search query
const filteredData = computed(() => {
  if (!props.searchQuery) {
    return flatData.value;
  }

  const query = props.searchQuery.toLowerCase();

  return flatData.value.filter((item) => {
    const titleMatch = item.title.toLowerCase().includes(query);
    const idMatch = item.dealID.toString().includes(query);
    const dateMatch = item.created_date.includes(query);
    return titleMatch || idMatch || dateMatch;
  });
});

// Пример функций для получения иконки, статуса и класса статуса по типу активности
function getIconByType(type) {
  switch (type) {
    case "set-commission":
      return "ti ti-user-share";
    case "update-bid-status":
      return "ti ti-phone";
    // Добавьте другие типы по необходимости
    default:
      return "ti ti-default";
  }
}

function getStatusByType(type) {
  switch (type) {
    case "set-commission":
      return "Комиссия";
    case "update-bid-status":
      return "Статус заявки";
    // Добавьте другие типы по необходимости
    default:
      return "Неизвестно";
  }
}

function getStatusClassByType(type) {
  switch (type) {
    case "set-commission":
      return "badge activity-badge bg-purple";
    case "update-bid-status":
      return "badge activity-badge bg-green";
    // Добавьте другие типы по необходимости
    default:
      return "badge activity-badge bg-secondary";
  }
}

const columns = ref([
  {
    title: "Название",
    dataIndex: "title",
    sorter: {
      compare: (a, b) => {
        const titleA = a.title.toLowerCase();
        const titleB = b.title.toLowerCase();
        return titleA > titleB ? -1 : titleB > titleA ? 1 : 0;
      },
    },
  },
  {
    title: "Сделка",
    dataIndex: "dealID",
    sorter: {
      compare: (a, b) => {
        const dealA = a.dealID.toLowerCase();
        const dealB = b.dealID.toLowerCase();
        return dealA > dealB ? -1 : dealB > dealA ? 1 : 0;
      },
    },
  },
  {
    title: "Дата создания",
    dataIndex: "created_date",
    sorter: {
      compare: (a, b) => {
        const dateA = new Date(a.created_date);
        const dateB = new Date(b.created_date);
        return dateA > dateB ? -1 : dateB > dateA ? 1 : 0;
      },
    },
  },
]);

const customRowHandler = (record) => {
  return {
    onClick: () => {
      router.push(
        record.lead_status === "rejected" ||
          record.lead_status === "new" ||
          record.lead_status === "reserved"
          ? `#`
          : `/deals/${record.dealID}`
      );
    },
  };
};
</script>
