<template>
  <a-table
    class="stripped table-hover"
    :columns="columns"
    :data-source="filteredData"
    :row-selection="rowSelection"
    :pagination="paginationConfig"
    :customRow="
      (record) => {
        return {
          onClick: () => {
            handleRowClick(record);
          },
        };
      }
    "
    row-class-name="clickable-row"
  >
    <template #bodyCell="{ column, record }">
      <template v-if="column.key === 'ID'">
        <div>
          <router-link
            :to="`/deals/${record.slug}`"
            class="fs-12 fw-normal text-black"
            >{{ record.ID }}</router-link
          >
        </div>
      </template>
      <template v-else-if="column.key === 'Created_at'">
        <div class="fs-12 fw-normal text-black">
          {{ formatDate(record.Created_at) }}
        </div>
      </template>
      <template v-else-if="column.key === 'Deal_Type'">
        <td>
          <span :class="record.TypeClass">{{ record.Deal_Type }}</span>
        </td>
      </template>
      <template v-else-if="column.key === 'INN'">
        <div class="fs-12 fw-normal text-black">{{ record.INN }}</div>
      </template>
      <template v-else-if="column.key === 'Deal_Status'">
        <td class="d-flex w-100">
          <span :class="record.StatusClass">{{
            record.Deal_Status === "accepted"
              ? "Принято"
              : record.Deal_Status === "reserved"
              ? "Забронировано"
              : record.Deal_Status === "finished"
              ? "Завершено"
              : "Отклонено"
          }}</span>
        </td>
      </template>
      <template v-else-if="column.key === 'Date'">
        <div class="fs-12 fw-normal text-black">
          {{ formatDate(record.Date) }}
        </div>
      </template>
      <template
        v-else-if="
          column.key === 'Deal_notifications' && !!record.Deal_notifications
        "
      >
        <div
          class="fs-12 fw-normal badge bg-primary-900 border-0"
          :style="{ minWidth: '38px' }"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="15"
            height="15"
            viewBox="0 0 15 15"
            fill="none"
          >
            <path
              d="M6.31259 11.5102L8.49259 13.6802C8.62634 13.8172 8.79357 13.917 8.97773 13.9696C9.16189 14.0223 9.3566 14.0259 9.54259 13.9802C9.72974 13.9366 9.90255 13.8457 10.0444 13.7161C10.1863 13.5865 10.2924 13.4226 10.3526 13.2402L13.9326 2.51015C14.0073 2.3091 14.0227 2.09083 13.977 1.88128C13.9313 1.67172 13.8264 1.4797 13.6747 1.32804C13.523 1.17638 13.331 1.07145 13.1215 1.02574C12.9119 0.980021 12.6936 0.995437 12.4926 1.07015L1.76259 4.65015C1.57384 4.71462 1.40573 4.82833 1.27564 4.97952C1.14554 5.13071 1.05818 5.3139 1.02259 5.51015C0.985848 5.68864 0.994027 5.87346 1.04639 6.04801C1.09876 6.22256 1.19366 6.38136 1.32259 6.51015L4.06259 9.25015L3.97259 12.7202L6.31259 11.5102Z"
              fill="white"
              stroke="#216B4E"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M13.6125 1.29004L4.0625 9.25004"
              stroke="#216B4E"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          {{ record.Deal_notifications }}
        </div>
      </template>
    </template>
  </a-table>
</template>

<script setup>
import { useRequestsStore } from "@/stores/requests";
import moment from "moment";
import { ref, computed, watch, onMounted } from "vue";
import { useRouter } from "vue-router";
import { useAuthStore } from "@/stores/auth";

const router = useRouter();

const props = defineProps({
  filter: {
    type: String,
    default: "all",
  },
});

const authStore = useAuthStore();
const requestsStore = useRequestsStore();
const formatDate = (date) => {
  return moment(date).locale("ru").format("DD MMM YYYY");
};

const paginationConfig = {
  defaultPageSize: 10,
  showSizeChanger: true,
  pageSizeOptions: ["10", "20", "30"],
  showTotal: (total, range) => `Показано ${range[0]}-${range[1]} из ${total}`,
  locale: {
    items_per_page: "элементов на странице",
  },
};

const data = ref([]);
const columns = [
  {
    title: "ID",
    dataIndex: "ID",
    key: "ID",
    sorter: {
      compare: (a, b) => {
        a = a.ID.toLowerCase();
        b = b.ID.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Дата создания",
    dataIndex: "Created_at",
    key: "Created_at",
    sorter: {
      compare: (a, b) => {
        return new Date(a.Created_at) - new Date(b.Created_at);
      },
    },
  },
  {
    title: "Тип сделки",
    dataIndex: "Deal_Type",
    key: "Deal_Type",
    sorter: {
      compare: (a, b) => {
        a = a.Deal_Type.toLowerCase();
        b = b.Deal_Type.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "ИНН",
    dataIndex: "INN",
    key: "INN",
    sorter: {
      compare: (a, b) => {
        a = a.INN.toLowerCase();
        b = b.INN.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Статус",
    dataIndex: "Deal_Status",
    key: "Deal_Status",
    sorter: {
      compare: (a, b) => {
        a = a.Deal_Status.toLowerCase();
        b = b.Deal_Status.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Дата",
    dataIndex: "Date",
    key: "Date",
    sorter: {
      compare: (a, b) => {
        return new Date(a.Date) - new Date(b.Date);
      },
    },
  },
  {
    title: "Уведомления",
    dataIndex: "Deal_notifications",
    key: "Deal_notifications",
    sorter: {
      compare: (a, b) => {
        a = parseInt(a.Deal_notifications?.trim().toLowerCase());
        b = parseInt(b.Deal_notifications?.trim().toLowerCase());
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "",
    dataIndex: "Action",
    key: "action",
    sorter: false,
  },
];
const rowSelection = {
  onChange: () => {},
  onSelect: () => {},
  onSelectAll: () => {},
};
const activeFilter = ref(props.filter);

watch(
  () => props.filter,
  (newFilter) => {
    activeFilter.value = newFilter;
  }
);

const handleRowClick = (record) => {
  console.log(record);
  router.push(`/deals/${record.slug}`);
};

const updateTableData = () => {
  data.value = requestsStore.getRequests.map((item) => {
    return {
      key: item.lead_id,
      ID: `№ ${item.lead_id.slice(0, 4)}`,
      slug: item.lead_id,
      Created_at: item.created_at,
      Deal_Type: "Лизинг",
      TypeClass: "badge badge-type",
      INN: item.inn || item.client?.inn || item.client_inn,
      Deal_Status: item.status,
      StatusClass: `badge badge-status badge-${item.status}`,
      Date: item.sent_at,
      Deal_notifications: item.notifications,
      Deal_comment: "",
      Controls: [
        {
          title: "Отклонить",
          class: "btn btn-primary-light",
          event: (e) => {
            e.stopPropagation();
            console.log("reject");
          },
        },
        {
          title: "Принять",
          class: "btn btn-primary",
          event: (e) => {
            e.stopPropagation();
            console.log("accept");
          },
        },
      ],
    };
  });
};

const filteredData = computed(() => {
  if (activeFilter.value === "all") {
    return data.value;
  }
  return data.value.filter(
    (item) =>
      item.Deal_Status.toLowerCase() === activeFilter.value.toLowerCase()
  );
});

onMounted(() => {
  updateTableData();
});

watch(
  () => requestsStore.getRequests,
  () => {
    updateTableData();
  }
);

watch(
  () => authStore.getCurrentManagerID,
  async () => {
    updateTableData();
  }
);
</script>

<style scoped>
.clickable-row {
  cursor: pointer;
}
</style>
