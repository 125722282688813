<template>
  <nav class="main-sidebar">
    <ul>
      <li>
        <router-link to="/requests" @click="deleteSlideNav">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="14"
            viewBox="0 0 18 14"
            fill="none"
            class="ico-email"
          >
            <path
              d="M15.7692 0.683838H2.23077C1.55103 0.683838 1 1.23487 1 1.91461V12.3761C1 13.0559 1.55103 13.6069 2.23077 13.6069H15.7692C16.449 13.6069 17 13.0559 17 12.3761V1.91461C17 1.23487 16.449 0.683838 15.7692 0.683838Z"
              fill="#005534"
              stroke="white"
              stroke-width="0.5"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="ico-email-fill"
            />
            <path
              d="M1 2.22241L8.21231 8.37626C8.43346 8.56046 8.71218 8.66134 9 8.66134C9.28782 8.66134 9.56654 8.56046 9.78769 8.37626L17 2.22241"
              fill="#005534"
              class="ico-email-fill"
            />
            <path
              d="M1 2.22241L8.21231 8.37626C8.43346 8.56046 8.71218 8.66134 9 8.66134C9.28782 8.66134 9.56654 8.56046 9.78769 8.37626L17 2.22241"
              stroke="white"
              stroke-width="0.5"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="ico-email-lines"
            />
          </svg>
          <p>
            Мои заявки
            <!-- TODO: add badge with dealss notifications-->
            <!-- <span class="badge bg-primary-900 border-0">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="9"
                height="10"
                viewBox="0 0 9 10"
                fill="none"
              >
                <g clip-path="url(#clip0_2086_3629)">
                  <path
                    d="M4.49986 1.00537C5.23129 1.00537 5.93276 1.29593 6.44996 1.81313C6.96716 2.33033 7.25772 3.0318 7.25772 3.76323C7.25772 6.82966 8.37629 7.43394 8.67843 7.43394H0.321289C0.62986 7.43394 1.742 6.82323 1.742 3.76323C1.742 3.0318 2.03256 2.33033 2.54976 1.81313C3.06696 1.29593 3.76843 1.00537 4.49986 1.00537Z"
                    fill="white"
                    stroke="white"
                    stroke-width="0.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M3.53516 8.61035C3.59073 8.8238 3.71554 9.01278 3.89005 9.14767C4.06455 9.28256 4.27888 9.35574 4.49944 9.35574C4.72 9.35574 4.93433 9.28256 5.10884 9.14767C5.28334 9.01278 5.40816 8.8238 5.46373 8.61035"
                    fill="white"
                  />
                  <path
                    d="M3.53516 8.61035C3.59073 8.8238 3.71554 9.01278 3.89005 9.14767C4.06455 9.28256 4.27888 9.35574 4.49944 9.35574C4.72 9.35574 4.93433 9.28256 5.10884 9.14767C5.28334 9.01278 5.40816 8.8238 5.46373 8.61035"
                    stroke="white"
                    stroke-width="0.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_2086_3629">
                    <rect
                      width="9"
                      height="9"
                      fill="white"
                      transform="translate(0 0.683838)"
                    />
                  </clipPath>
                </defs>
              </svg>
              2
            </span> -->
          </p>
        </router-link>
      </li>
      <li>
        <router-link
          to="/deals"
          :class="{ active: $route.path.startsWith('/deals') }"
          @click="deleteSlideNav"
        >
          <svg
            width="16"
            height="23"
            viewBox="0 0 16 23"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            class="ico-deals"
          >
            <path
              d="M1.14258 18.9695H14.8569"
              stroke="#005534"
              stroke-width="0.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M2.28613 22.3982H13.7147"
              stroke="#005534"
              stroke-width="0.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <g clip-path="url(#clip0_2086_3611)">
              <path
                d="M14.2856 4.68384H1.71415C1.08296 4.68384 0.571289 5.19551 0.571289 5.8267V14.9696C0.571289 15.6007 1.08296 16.1124 1.71415 16.1124H14.2856C14.9168 16.1124 15.4284 15.6007 15.4284 14.9696V5.8267C15.4284 5.19551 14.9168 4.68384 14.2856 4.68384Z"
                fill="#005534"
                stroke="white"
                stroke-width="0.5"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="ico-deals-fill"
              />
              <path
                d="M5.71415 1.25537H10.2856C10.5887 1.25537 10.8794 1.37578 11.0937 1.59011C11.308 1.80443 11.4284 2.09512 11.4284 2.39823V4.68394H4.57129V2.39823C4.57129 2.09512 4.6917 1.80443 4.90602 1.59011C5.12035 1.37578 5.41104 1.25537 5.71415 1.25537Z"
                stroke="#005534"
                stroke-width="0.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M4 8.68384H12"
                stroke="white"
                stroke-width="0.5"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="ico-deals-lines-inner"
              />
              <path
                d="M4 12.1125H12"
                stroke="white"
                stroke-width="0.5"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="ico-deals-lines-inner"
              />
            </g>
            <defs>
              <clipPath id="clip0_2086_3611">
                <rect
                  width="16"
                  height="16"
                  fill="#005534"
                  transform="translate(0 0.683838)"
                />
              </clipPath>
            </defs>
          </svg>
          <p>Сделки</p>
        </router-link>
      </li>
      <li>
        <router-link to="/settings" @click="deleteSlideNav">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="17"
            height="17"
            viewBox="0 0 17 17"
            fill="none"
            class="ico-settings"
          >
            <path
              d="M7.10417 1.78134C7.45917 0.318005 9.54083 0.318005 9.89583 1.78134C9.9491 2.00117 10.0535 2.20532 10.2006 2.37717C10.3477 2.54902 10.5332 2.68372 10.7422 2.77029C10.9512 2.85687 11.1776 2.89288 11.4032 2.8754C11.6287 2.85791 11.8469 2.78742 12.04 2.66967C13.3258 1.88634 14.7983 3.358 14.015 4.64467C13.8974 4.83772 13.827 5.05579 13.8096 5.28115C13.7922 5.50651 13.8281 5.73281 13.9146 5.94165C14.0011 6.1505 14.1356 6.33599 14.3073 6.48305C14.4789 6.63011 14.6829 6.73459 14.9025 6.788C16.3658 7.143 16.3658 9.22467 14.9025 9.57967C14.6827 9.63294 14.4785 9.73736 14.3067 9.88443C14.1348 10.0315 14.0001 10.2171 13.9135 10.426C13.827 10.635 13.791 10.8615 13.8084 11.087C13.8259 11.3125 13.8964 11.5307 14.0142 11.7238C14.7975 13.0097 13.3258 14.4822 12.0392 13.6988C11.8461 13.5813 11.6281 13.5109 11.4027 13.4934C11.1773 13.476 10.951 13.512 10.7422 13.5984C10.5333 13.6849 10.3479 13.8195 10.2008 13.9911C10.0537 14.1628 9.94925 14.3667 9.89583 14.5863C9.54083 16.0497 7.45917 16.0497 7.10417 14.5863C7.0509 14.3665 6.94648 14.1624 6.79941 13.9905C6.65233 13.8187 6.46676 13.684 6.25779 13.5974C6.04882 13.5108 5.82236 13.4748 5.59685 13.4923C5.37133 13.5098 5.15313 13.5803 4.96 13.698C3.67417 14.4813 2.20167 13.0097 2.985 11.723C3.10258 11.53 3.17296 11.3119 3.1904 11.0865C3.20785 10.8612 3.17187 10.6349 3.08539 10.426C2.99892 10.2172 2.86438 10.0317 2.69273 9.88463C2.52107 9.73757 2.31714 9.63308 2.0975 9.57967C0.634167 9.22467 0.634167 7.143 2.0975 6.788C2.31733 6.73474 2.52148 6.63032 2.69333 6.48325C2.86518 6.33617 2.99988 6.1506 3.08645 5.94163C3.17303 5.73266 3.20904 5.5062 3.19156 5.28068C3.17407 5.05517 3.10359 4.83696 2.98583 4.64384C2.2025 3.358 3.67417 1.8855 4.96083 2.66884C5.79417 3.1755 6.87417 2.72717 7.10417 1.78134Z"
              stroke="#005534"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M6 8.18384C6 8.84688 6.26339 9.48276 6.73223 9.95161C7.20107 10.4204 7.83696 10.6838 8.5 10.6838C9.16304 10.6838 9.79893 10.4204 10.2678 9.95161C10.7366 9.48276 11 8.84688 11 8.18384C11 7.5208 10.7366 6.88491 10.2678 6.41607C9.79893 5.94723 9.16304 5.68384 8.5 5.68384C7.83696 5.68384 7.20107 5.94723 6.73223 6.41607C6.26339 6.88491 6 7.5208 6 8.18384Z"
              stroke="#005534"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <p>Настройки</p>
        </router-link>
      </li>
    </ul>
  </nav>
</template>

<script setup>
// method for deleting slide-nav class from body
const deleteSlideNav = () => {
  document.body.classList.remove("slide-nav");
};
</script>
