<template>
  <div class="verification-success-wrapper">
    <div class="verification-success">
      <div class="card">
        <div class="card-body">
          <div class="text-center mb-4 login-logo">
            <img src="@/assets/img/logo.svg" class="img-fluid" alt="Logo" />
          </div>
          <div class="verification-success-text mb-4 text-center">
            <h4 class="mb-3 fs-18 fw-medium">
              Ваша заявка на регистрацию на портале <br />
              «GCAP Лизинг» успешно отправлена.
            </h4>
            <p class="fs-12">
              Временные логин и пароль для доступа будут отправлены на указанный
              вами адрес электронной почты в течение 24 часов.
            </p>
          </div>
        </div>
      </div>
    </div>
    <contacts-group />
  </div>
</template>

<style lang="scss" scoped>
.verification-success {
  display: flex;
  justify-content: center;
  align-items: center;
  &-wrapper {
    width: 100%;
  }
  width: 100%;

  &-text p {
    max-width: 468px;
    margin: 0 auto;
    color: #a2a2a2;
  }
}
</style>
