<template>
  <div>
    <a-table
      class="stripped table-desktop"
      :columns="columns"
      :data-source="data"
      :row-selection="rowSelection"
      :pagination="false"
    >
      <template #bodyCell="{ column, record }">
        <template v-if="column.key === 'ID'">
          <div>
            <div class="fs-12 fw-normal text-black">{{ record.ID }}</div>
          </div>
        </template>
        <template v-else-if="column.key === 'Created_at'">
          <div class="fs-12 fw-normal text-black">
            {{ formatDate(record.Created_at) }}
          </div>
        </template>
        <template v-else-if="column.key === 'Deal_Type'">
          <td>
            <span :class="record.TypeClass">{{ record.Deal_Type }}</span>
          </td>
        </template>
        <template v-else-if="column.key === 'INN'">
          <div class="fs-12 fw-normal text-black">{{ record.INN }}</div>
        </template>
        <template v-else-if="column.key === 'Deal_Status'">
          <td class="d-flex w-100">
            <span :class="record.StatusClass"
              >{{ record.Deal_Status === "new" ? "Новая" : "Отклонена" }}
            </span>
          </td>
        </template>
        <template v-else-if="column.key === 'Controls'">
          <div class="d-flex gap-2">
            <button
              v-for="control in record.Controls"
              :key="control.title"
              class="btn-w-s fs-12 w-auto"
              :class="control.class"
              @click="control.event"
              :data-bs-toggle="control.type === 'reject' ? 'modal' : ''"
              :data-bs-target="
                control.type === 'reject' ? '#delete_campaign' : '#'
              "
              :style="{ minWidth: '90px' }"
            >
              {{ control.title }}
            </button>
          </div>
        </template>
      </template>
    </a-table>

    <div class="table-mobile-wrapper">
      <table class="table dataTable table-mobile">
        <thead class="thead-light">
          <tr>
            <template
              v-for="(column, index) in columns.filter(
                (_, i) => i <= 2 || i === 5 || i === 6
              )"
              :key="column.key"
            >
              <template v-if="index !== 2">
                <th>{{ column.title }}</th>
              </template>
            </template>
          </tr>
        </thead>
        <tbody>
          <template v-for="item in data" :key="item.key">
            <tr class="border-none">
              <td>{{ item.ID }}</td>
              <td>{{ formatDate(item.Created_at) }}</td>

              <td>{{ item.INN }}</td>
              <td>
                <span :class="item.StatusClass">{{
                  item.Deal_Status === "new" ? "Новая" : "Отклонена"
                }}</span>
              </td>
            </tr>
            <tr>
              <td colspan="5">
                <div class="d-flex gap-2">
                  <button
                    v-for="control in item.Controls"
                    :key="control.title"
                    class="btn-w-s fs-12 w-auto"
                    :class="control.class"
                    @click="control.event"
                    :data-bs-toggle="
                      control.type === 'reject' ? 'modal' : 'modal'
                    "
                    :data-bs-target="
                      control.type === 'reject'
                        ? '#delete_campaign'
                        : '#accept_campaign'
                    "
                  >
                    {{ control.title }}
                  </button>
                </div>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>

    <teleport to="#app">
      <div class="modal fade" id="delete_campaign" role="dialog">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-body">
              <div class="text-center">
                <button
                  type="button"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  ref="rejectModalCloseEl"
                  class="modal-btn-close"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="25"
                    viewBox="0 0 25 25"
                    fill="none"
                  >
                    <path
                      d="M19.7913 5.2085L5.20801 19.7918"
                      stroke="#001615"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M19.7913 19.7918L5.20801 5.2085"
                      stroke="#001615"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </button>
                <h4 class="mb-2">Укажите причину отказа</h4>
                <p class="mb-4">
                  Пожалуйста, укажите причину отказа, чтобы мы могли
                  зафиксировать это в системе.
                </p>

                <div class="dropdown dropdown-gray mb-2 w-100">
                  <a
                    href="#"
                    ref="rejectReasonDropdownEl"
                    class="text-dark d-inline-flex align-items-center justify-content-between gap-2 w-100"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <template v-if="rejectReason">
                      {{ rejectReason }}
                    </template>
                    <template v-else> Выберите из списка </template>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="13"
                      height="7"
                      viewBox="0 0 13 7"
                      fill="none"
                    >
                      <path
                        d="M1.36426 1L6.86426 6L12.3643 1"
                        stroke="#000000"
                        stroke-linecap="ceil"
                        stroke-linejoin="ceil"
                      />
                    </svg>
                  </a>
                  <div class="dropdown-menu">
                    <a
                      class="dropdown-item"
                      href="javascript:void(0);"
                      @click.stop="
                        handleRejectReason('Отсутствие необходимых документов')
                      "
                      ><span>Отсутствие необходимых документов</span></a
                    >
                    <a
                      class="dropdown-item"
                      href="javascript:void(0);"
                      @click.stop="
                        handleRejectReason('Несоответствие требованиям')
                      "
                      ><span>Несоответствие требованиям</span></a
                    ><a
                      class="dropdown-item"
                      href="javascript:void(0);"
                      @click.stop="
                        handleRejectReason('Недостаточная платежеспособность')
                      "
                      ><span>Недостаточная платежеспособность</span></a
                    >
                  </div>
                </div>
                <textarea
                  class="form-control"
                  :style="{ resize: 'none', padding: '10px 15px' }"
                  rows="4"
                  placeholder="Комментарий*"
                  v-model="rejectReasonComment"
                ></textarea>
                <div
                  class="d-flex align-items-center justify-content-center mt-4"
                >
                  <a
                    href="#"
                    class="btn btn-primary w-100"
                    @click="handleReject"
                    >Отправить</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </teleport>
  </div>
</template>

<script setup>
import { useRequestsStore } from "@/stores/requests";
import { useAuthStore } from "@/stores/auth";
import { ref, watch, onMounted } from "vue";
import moment from "moment";
import { useRouter } from "vue-router";
import { useToast } from "vue-toast-notification";
import { useEventSource } from "@vueuse/core";
import { baseUrl } from "@/config";
const toast = useToast();
const requestsStore = useRequestsStore();
const authStore = useAuthStore();
const router = useRouter();
const columns = [
  {
    title: "ID",
    dataIndex: "ID",
    key: "ID",
    sorter: {
      compare: (a, b) => {
        a = a.ID.toLowerCase();
        b = b.ID.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Дата создания",
    dataIndex: "Created_at",
    key: "Created_at",
    sorter: {
      compare: (a, b) => {
        return new Date(a.Created_at) - new Date(b.Created_at);
      },
    },
  },
  {
    title: "Тип сделки",
    dataIndex: "Deal_Type",
    key: "Deal_Type",
    sorter: {
      compare: (a, b) => {
        a = a.Deal_Type.toLowerCase();
        b = b.Deal_Type.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },

  {
    title: "Марка",
    dataIndex: "Brand_Type",
    key: "Brand_type",
    sorter: {
      compare: (a, b) => {
        a = a.Brand_Type.toLowerCase();
        b = b.Brand_Type.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Модель",
    dataIndex: "Model_Type",
    key: "Model_type",
    sorter: {
      compare: (a, b) => {
        a = a.Model_Type.toLowerCase();
        b = b.Model_Type.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "ИНН",
    dataIndex: "INN",
    key: "INN",
    sorter: {
      compare: (a, b) => {
        a = a.INN.toLowerCase();
        b = b.INN.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Статус",
    dataIndex: "Deal_Status",
    key: "Deal_Status",
    sorter: {
      compare: (a, b) => {
        a = a.Deal_Status.toLowerCase();
        b = b.Deal_Status.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Действия",
    dataIndex: "Controls",
    key: "Controls",
    sorter: false,
  },
];

const rowSelection = {
  onChange: () => {},
  onSelect: () => {},
  onSelectAll: () => {},
};

const data = ref([]);

const formatDate = (date) => {
  return moment(date).locale("ru").format("DD MMM YYYY");
};

// Функция для обновления данных таблицы
const updateTableData = () => {
  data.value = requestsStore.getNewBids
    .map((item) => ({
      key: item.lead_id,
      ID: `№ ${item.lead_id.slice(0, 4)}`,
      Created_at: item.created_at,
      Deal_Type: "Лизинг",
      TypeClass: "badge badge-type",
      Model_Type: item.fr_model.full_name,
      Brand_Type: item.fr_brand.full_name,
      INN: item.inn || item.client?.inn || item.client_inn,
      Deal_Status: item.status,
      StatusClass: `badge badge-status badge-${item.status}`,
      Date: item.sent_at,
      Deal_comment: "",
      Controls: [
        {
          title: "Отклонить",
          type: "reject",
          class: "btn btn-primary-light",
          event: (e) => {
            selectedDealID.value = item.lead_id;
          },
        },
        {
          title: item.can_be_accepted ? "Принять" : "Бронировать",
          type: "accept",
          class: "btn btn-primary",
          event: (e) => {
            e.stopPropagation();
            e.preventDefault();
            selectedDealID.value = item.lead_id;
            handleAccept();
          },
        },
      ],
    }))
    .filter((item) => item.Deal_Status === "new");
};

onMounted(async () => {
  updateTableData();
});

const selectedDealID = ref(null);
const rejectModalCloseEl = ref(null);
const rejectReasonDropdownEl = ref(null);
const rejectReason = ref(null);
const rejectReasonComment = ref(null);

const acceptModalCloseEl = ref(null);
const acceptManagerID = ref(authStore.getCurrentManagerID || null);

watch(
  () => authStore.getCurrentManagerID,
  async (newManagerID) => {
    acceptManagerID.value = newManagerID;
    updateTableData();
  }
);
const handleRejectReason = (reason) => {
  rejectReason.value = reason;
  rejectReasonDropdownEl.value.click();
};

const handleReject = async (e) => {
  if (!rejectReason.value || !rejectReasonComment.value) {
    e.stopPropagation();
    e.preventDefault();
    toast.error("Пожалуйста, укажите причину отказа");
    return;
  }
  const data = {
    leadId: selectedDealID.value,
    comment: rejectReasonComment.value,
    managerId: acceptManagerID.value,
  };
  try {
    const response = await requestsStore.rejectRequest(data);
    if (response.data.status === "rejected") {
      rejectModalCloseEl.value?.click();
      await requestsStore.fetchRequests();

      toast.success("Сделка отклонена");
    } else {
      toast.error("Произошла ошибка при отклонении сделки");
    }
  } catch (error) {
    console.log(error);
    toast.error("Произошла ошибка при отклонении сделки");
  }
};

const emit = defineEmits(["update:chooseManager"]);

const handleAccept = async () => {
  if (!acceptManagerID.value) {
    emit("update:chooseManager");
    toast.error("Пожалуйста, выберите менеджера");
    return;
  }
  const data = {
    leadId: selectedDealID.value,
    managerId: acceptManagerID.value,
  };

  try {
    const response = await requestsStore.acceptRequest(data);
    if (
      response.data.status === "accepted" ||
      response.data.status === "reserved"
    ) {
      acceptModalCloseEl.value?.click();
      router.push(`/deals/${response.data.lead_id}`);
      toast.success(
        response.data.status === "accepted"
          ? "Сделка принята"
          : "Сделка забронирована"
      );
      await requestsStore.fetchRequests();
    } else {
      toast.error("Произошла ошибка при принятии сделки");
    }
  } catch (error) {
    console.log(error);
    toast.error("Произошла ошибка при принятии сделки");
  }
};

// Если данные могут измениться обновляем таблицу
watch(
  () => requestsStore.getRequests,
  (newRequests) => {
    updateTableData();
  }
);
</script>

<style lang="scss" scoped>
.table-desktop {
  display: block;
  @media (max-width: 768px) {
    display: none;
  }
}

.table-mobile-wrapper {
  display: none;
  border-radius: 8px 8px 0 0;
  border: 1px solid #e9f2f6;
  @media (max-width: 768px) {
    display: block;
  }
}
.table-mobile {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  tr,
  th,
  td {
    font-size: 12px !important;
  }
  thead tr {
    border-color: transparent !important;
  }
  th {
    background-color: #fafafa;
    padding: 16px 8px;
    font-weight: 400 !important;
    &:first-child {
      border-radius: 8px 8px 0 0;
    }
    &:last-child {
      border-radius: 0 8px 8px 0;
    }
  }
  tr.border-none td {
    border-bottom: none !important;
  }
  button {
    width: 50% !important;
  }

  td {
    padding-left: 8px;
    padding-right: 8px;
    align-content: center;
  }

  tr:nth-child(odd) {
    td {
      padding-top: 16px;
    }
  }
  tr:nth-child(even) {
    td {
      padding-bottom: 16px;
    }
  }
}
</style>
