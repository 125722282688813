<template>
  <div class="verification-email">
    <form
      method="get"
      class="digit-group login-form-control"
      data-group-name="digits"
      data-autosubmit="false"
      autocomplete="off"
      @submit.prevent="submitForm"
    >
      <div class="mx-auto mw-450">
        <div class="text-center mb-4 login-logo">
          <img src="@/assets/img/logo.svg" class="img-fluid" alt="Logo" />
        </div>
        <div class="mb-4 text-center">
          <h4 class="mb-3 fs-18 fw-medium">
            Верификация через {{ type === "email" ? "Email" : "SMS" }}
          </h4>
          <p class="fs-12">
            Мы отправили одноразовый код подтверждения на ваш
            {{ type === "email" ? "email" : "номер телефона" }}. <br /><br />
            Пожалуйста, введите код в соответствующее поле, чтобы завершить
            процесс.
          </p>
        </div>
        <div class="input-digit-group mb-4">
          <input
            type="text"
            class="input-digit"
            id="digit-1"
            name="digit-1"
            data-next="digit-2"
            maxlength="1"
            @input="moveFocus($event, 'digit-2')"
            @keydown.backspace="handleBackspace($event, 'digit-1')"
          />
          <input
            type="text"
            class="input-digit"
            id="digit-2"
            name="digit-2"
            data-next="digit-3"
            data-previous="digit-1"
            maxlength="1"
            @input="moveFocus($event, 'digit-3')"
            @keydown.backspace="handleBackspace($event, 'digit-2')"
          />
          <input
            type="text"
            class="input-digit"
            id="digit-3"
            name="digit-3"
            data-next="digit-4"
            data-previous="digit-2"
            maxlength="1"
            @input="moveFocus($event, 'digit-4')"
            @keydown.backspace="handleBackspace($event, 'digit-3')"
          />
          <input
            type="text"
            class="input-digit"
            id="digit-4"
            name="digit-4"
            data-next="digit-5"
            data-previous="digit-3"
            maxlength="1"
            @input="moveFocus($event, 'digit-5')"
            @keydown.backspace="handleBackspace($event, 'digit-4')"
          />
        </div>

        <div class="text-center mb-4">
          <a href="#" class="link-hover fw-bold">Запросить новый код</a>
        </div>

        <div class="mb-3">
          <button type="submit" class="btn btn-primary w-100 register-btn">
            Подтвердить
          </button>
        </div>
      </div>
    </form>
    <contacts-group />
  </div>
</template>

<script setup>
defineProps({
  type: {
    type: String,
    required: true,
  },
});

const submitForm = () => {
  console.log("submitForm");
};

const moveFocus = (event, nextId) => {
  const input = event.target;
  if (input.value.length === 1) {
    const nextInput = document.getElementById(nextId);
    if (nextInput) {
      nextInput.focus();
    }
  }
};

const handleBackspace = (event) => {
  const input = event.target;
  if (input.value.length === 0) {
    const previousInput = document.getElementById(input.dataset.previous);
    if (previousInput) {
      previousInput.focus();
    }
  }
};
</script>

<style scoped lang="scss">
.input-digit-group {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
}
.input-digit {
  border: 1px solid #005534;
  border-radius: 5px;
  padding: 10px;
  width: 42px;
  height: 48px;
  text-align: center;
  font-weight: bold;
}

.verification-email {
  p {
    color: #a2a2a2;
  }
}
</style>
